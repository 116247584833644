$loader-size: 30px;

.c-video-container {
    position: relative;
    border-radius: 24px;
    .c-video {
        border-radius: inherit;
        height: 100%;
        object-fit: cover;
        width: 100%;
        &::-webkit-media-controls {
            visibility: hidden;
        }
        &::-webkit-media-controls-enclosure {
            visibility: visible;
        }
    }
    .c-video-loader {
        animation: video-spin 1s linear infinite;
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid #555;
        display: none;
        height: $loader-size;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: calc(var(-$loader-size) / 2);
        position: absolute;
        right: 0;
        text-align: center;
        top: 50%;
        width: $loader-size;
    }
    &.c-video-loading {
        background: #ffffff;
        .c-video-loader {
            display: block;
        }
    }

    .video-controls.show {
        opacity: 1;
        visibility: visible;
    }

    .video-controls {
        bottom: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;

        top: 0;
        -webkit-transition: opacity 700ms, visibility 700ms;
        transition: opacity 700ms, visibility 700ms;
        visibility: hidden;
        z-index: 3;
        &__btnPlayPause {
            align-items: center;
            backdrop-filter: blur(8px);
            background-color: #00000050;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            height: 120px;
            justify-content: center;
            left: 50%;
            margin: 0;
            position: absolute;
            top: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            transition: all 0.5s ease-in-out;

            width: 120px;

            &::after {
                border-bottom: 25px solid transparent;
                border-left: 45px solid #ffffff;
                border-top: 25px solid transparent;
                content: '';
                height: 0;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-31%, -50%);
                transition: transform 0.3s ease-in-out;
                width: 0;
            }
            &::before {
                content: '';
                height: 30%;
                left: calc(50% - 10px);
                position: absolute;
                top: 50%;
            }

            &.play {
                &::after {
                    background: #ffffff;
                    border: none;
                    content: '';
                    height: 30%;
                    left: calc(50% + 10px);
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 10px;
                }
                &::before {
                    background: #ffffff;
                    border: none;
                    content: '';
                    height: 30%;
                    left: calc(50% - 10px);
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 10px;
                }
            }
        }
        &__btnMuted {
            cursor: pointer;
            position: absolute;
            right: 16px;
            top: 16px;
            transition: all 0.5s ease-in-out;
            z-index: 2;
        }
        &__btnTopRight {
            cursor: pointer;
            left: 16px;
            position: absolute;
            top: 23px;
            transition: all 0.5s ease-in-out;
            z-index: 4;
            @media screen and( min-width:768px) {
                .icon-back {
                    display: none;
                }
            }
            @media screen and( max-width:768px) {
                .icon-close {
                    display: none;
                }
            }
        }
    }
}
.buttonPlay {
    button {
        height: 75px !important;
        width: 75px !important;
        svg {
            height: 22px !important;
            max-height: 22px !important;
            max-width: 22px !important;
            width: 22px !important;
        }
    }

    span {
        color: white !important;
        font-size: 33.1918px;
        font-weight: bold;
        line-height: 43px;
        text-decoration: none !important;
    }
}
.controlOverLay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    border-radius: inherit;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.3s ease-in-out;
    &.hide {
        opacity: 0;
    }
    &.show {
        opacity: 1;
    }
}
@media screen and(max-width:768px) {
    .buttonPlay {
        height: 40px;

        button {
            height: 40px !important;
            width: 40px !important;
            svg {
                height: 12px !important;
                max-height: 12px !important;
                max-width: 12px !important;
                width: 12px !important;
            }
        }

        span {
            font-size: 20px;
            line-height: 28px;
        }
    }
}
@media screen and(max-width:576px) {
    .buttonPlay {
        height: 40px;

        button {
            height: 30px !important;
            width: 30px !important;
            svg {
                height: 10px !important;
                max-height: 10px !important;
                max-width: 10px !important;
                width: 10px !important;
            }
        }

        span {
            font-size: 20px;
            line-height: 28px;
        }
    }
}
/* Safari */
@-webkit-keyframes video-spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes video-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
