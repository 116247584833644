.categoryHero {
    width: 1440px;
    max-width: 100%;
    margin: 0 auto;
    transition-delay: 0.3s;
    padding: 0px 60px;
    
    position: relative;

    &:not(.breadcrumb) {
        .categoryHeroBackground {
            height: calc(100% + 136px);
            margin-top: -136px;
        }
    }
}

.breadcrumbs {
    margin-top: 64px;
}

.categoryHeroBackground {
    background-color: #eff4fb;
    border-radius: 0px 0px 24px 24px;
    width: 100vw;
    min-width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
    transition: height 0.3s ease;

    &Cover {
        height: calc(100% + 202px);
        min-height: 500px;
    }

    &OrcaPastel {
        background-color: #eff4fb;
    }
    &Blum {
        background-color: #eaddf2;
    }
}
.categoryName {
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
    margin: 48px 0 0 0px;
}
.categoryDescription {
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    margin: 17px 0 0 0px;
    margin-bottom: 35px;
}
.categoryFilter {
    margin-left: -16px;
    min-height: 1px;
}
@media screen and (max-width: 768px) {
    .categoryHero {
        padding: 0 15px;
        &:not(.breadcrumb) {
            padding-top: 41px;
            .categoryHeroBackground {
                height: calc(100% + 56px);
                margin-top: -56px;
            }
        }
    }
    .categoryName {
        font-weight: bold;
        font-size: 56px;
        line-height: 64px;
        margin: 22px 0 0 15px;
    }
    .categoryDescription {
        font-size: 24px;
        line-height: 32px;
        margin: 10px 0 0 15px;
    }
    .categoryHeroBackground {
        &Cover {
            min-height: 318px;
            height: calc(100% + 117px);
        }
    }
    .categoryDescription {
        margin-bottom: 17px;
    }
}
