$business-resouce-training-block-item-prefix: businessResourceTrainingBlockItem;

.#{$business-resouce-training-block-item-prefix}Container {
    border-radius: 12px;
    display: flex;
    height: 530px;
    
    overflow: hidden;
    padding: 8px;
    position: relative;

    &:hover {
        .#{$business-resouce-training-block-item-prefix} {
            &Background {
                .box {
                    .main {
                        -webkit-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        -ms-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    .#{$business-resouce-training-block-item-prefix} {
        &Background {
            border-radius: 12px;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 100%;
            .box {
                border-radius: inherit;
                height: 100%;
                overflow: hidden;
                position: relative;
                transform: rotate(0);
                width: 100%;
                .main {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 12px;
                    height: 100%;
                    left: 0;
                    overflow: hidden;
                    position: absolute;
                    right: 0;
                    transition: transform 0.3s;

                    width: 100%;
                    z-index: 1;
                }
            }
        }
        &Content {
            background-color: #ffffff;
            border-radius: 8px;
            cursor: pointer;
            margin-top: auto;
            padding: 24px;
            width: 100%;
            z-index: 1;

            .content {
                &Title {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 32px;
                    margin-bottom: 8px;
                }
                &Description {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    margin-bottom: 16px;
                    max-height: 4.4em;

                    min-height: 4.4em;
                    overflow: hidden;
                }

                &CTA {
                    background: #b2fce4;
                    border-radius: 100px;
                    color: #000000;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    padding: 12px 24px;
                    text-align: center;
                    text-decoration: none;
                    transition: background-color 0.3s ease-in-out;
                    width: fit-content;
                    &:visited {
                        color: #000000;
                    }
                    svg {
                        margin-left: 14.5px;
                        vertical-align: middle;

                        path {
                            stroke: #000000;
                        }
                    }

                    &:hover {
                        background-color: #000000;
                        color: #ffffff;
                        svg {
                            path {
                                stroke: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 979px) {
        height: 400px;

        .#{$business-resouce-training-block-item-prefix} {
            &Content {
                padding: 16px;

                .content {
                    &Title {
                        font-size: 24px;
                        line-height: 32px;
                    }
                    &Description {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .#{$business-resouce-training-block-item-prefix} {
            &Content {
                width: 100%;
                .content {
                    &CTA {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}
