$business-resouce-card-prefix: businessResourceHeroSub;

.#{$business-resouce-card-prefix}Wrapper {
    position: relative;
    width: 100%;
    &.breadcrumb {
        padding-top: 88px;
    }
    .#{$business-resouce-card-prefix} {
        &Breadcrumb {
            left: 0;
            position: absolute;
            top: 0;
            z-index: 200;
        }
        &BackgroundWrapper {
            border-radius: 24px;
            height: 600px;
            overflow: hidden;
            position: relative;
            width: 100%;
            .box {
                height: 100%;
                overflow: hidden;
                width: 100%;
                .main {
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 100%;
                    transition: transform 0.3s;
                    width: 100%;
                }
            }
        }

        &OverlayWrapper {
            background-color: #ffffff;
            border-radius: 0px 24px 0px 0px;
            bottom: 0;
            left: 0px;
            padding: 40px 40px 0px 0px;
            position: absolute;

            width: 100%;
            max-width: 648px;

            .#{$business-resouce-card-prefix}Overlay {
                &Title {
                    font-size: 56px;
                    font-weight: bold;
                    line-height: 64px;
                    margin-bottom: 16px;
                }
                &Description {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }
    }

    @media only screen and (max-width: 979px) {
        .#{$business-resouce-card-prefix} {
            &BackgroundWrapper {
                height: 420px;
            }
            &OverlayWrapper {
                .#{$business-resouce-card-prefix}Overlay {
                    &Title {
                        font-size: 40px;
                        line-height: 52px;
                        margin-bottom: 16px;
                    }
                    &Description {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 908px) {
        .#{$business-resouce-card-prefix}OverlayWrapper {
            width: calc(100% - 168px);
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0;
        width: 100vw;
        margin-left: calc(50% - 50vw);
        &.breadcrumb {
            padding-top: 0;
        }

        .#{$business-resouce-card-prefix} {
            &Breadcrumb {
                left: 15px;
            }
            &BackgroundWrapper {
                border-radius: 0px 0px 24px 24px;
                &::before {
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 24.75%, rgba(0, 0, 0, 0) 39.97%);
                    content: '';
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: -64px;
                    width: 100%;
                    z-index: 1;
                }
            }
            &OverlayWrapper {
                border-radius: 8px;
                left: auto;
                margin-bottom: -24px;
                margin-left: 15px;
                margin-right: 15px;
                padding: 24px 16px 0 16px;
                position: relative;

                transform: translateY(-78px);
                width: auto;
            }
        }
    }
}
