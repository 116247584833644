$business-trending-topics-prefix: businessTrendingTopics;

.#{$business-trending-topics-prefix}Item {
    border-radius: 12px;
    height: 200px;
    overflow: hidden;
    position: relative;
    width: 200px;

    display: block;

    .item {
        &Background {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
            border-radius: 12px;
            overflow: hidden;

            &::after {
                content: '';
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 49.45%, rgba(0, 0, 0, 0.5) 75.5%);
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                pointer-events: none;
            }

            :global {
                .image-zoom {
                    border-radius: 12px;
                }
            }
        }
        &Description {
            bottom: 0;
            color: white;
            font-size: 20px;
            font-weight: 400;
            left: 0;
            line-height: 28px;
            max-width: 100%;
            padding: 16px;
            position: absolute;
            text-align: center;
            width: 100%;
            z-index: 1;
        }
    }

    @media only screen and (max-width: 767px) {
        height: 140px;
        width: 140px;

        .item {
            &Description {
                font-size: 14px;
                line-height: 22px;
                padding: 12px 16px;
            }
        }
    }
}
