.root {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
        gap: 32px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

.resourceContainer {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 200px);
    gap: 24px;
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, min(100%, 345px));
    }
}

.pagePreviewImage {
    max-width: 536px;
    width: 100%;
}

.resourceGroup {
    //margin-top: 40px;
}
