.c-background-zoom {
    overflow: hidden;
    .image-box {
        width: 100%;
        padding-top: 100%;
        background: transparent;
        position: relative;
        overflow: hidden;
        transition: transform 0.3s;
        transform: rotate(0);
        border-radius: inherit;
        .image-main {
            width: 100%;
            cursor: pointer;
            transition: all 0.3s ease-in;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
    &:hover {
        .image-main {
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -o-transform: scale(1.1);
            -ms-transform: scale(1.1); /* IE 9 */
            transform: scale(1.1);
        }
    }
}
