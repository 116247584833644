@import '../../styles/styles';
.communityCard {
    position: relative;
    border-radius: 12px;
    width: 720px;
    max-width: 100%;
    cursor: pointer;
    overflow: hidden;
    :global {
        .image-zoom {
            border-radius: 12px;
        }
    }
    &:hover {
        :global {
            .image-zoom {
                .image-hover-zoom {
                    -webkit-transform: scale(1.1);
                    -moz-transform: scale(1.1);
                    -o-transform: scale(1.1);
                    -ms-transform: scale(1.1);
                    transform: scale(1.1);
                }
            }
        }
    }
}

.overlay {
    border-radius: 12px;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 27.14%, rgba(0, 0, 0, 0.5) 53.08%);
}
.infoArticle {
    position: absolute;
    bottom: 40px;
    left: 40px;
    right: 40px;
    max-width: 100%;
    border-radius: inherit;
    overflow: hidden;

    z-index: 2;
    div:first-of-type {
        > div {
            color: white !important;
            border-color: white;
        }
    }
}
.readMoreBtn {
    position: relative;
    z-index: 3;
    
    a {
        margin: 0;
    }
}
.communityCardWrapper {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}

.articleTitle {
    margin: 16px 0;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.articleDescription {
    color: white;
    font-size: 20px;
    line-height: 28px;
    display: -webkit-box;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 24px;
}
.articleUrl {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    z-index: 2;
}

@media screen and (max-width: 576px) {
    .articleDescription {
        display: none;
    }
    .communityCard {
        width: 345px;
        position: relative;
    }
    .infoArticle {
        padding: 16px;
        width: 100%;
        left: 0;
        bottom: 0;
    }
    .articleTitle {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 0;
    }
    .readMoreBtn {
        display: none;
    }
}
