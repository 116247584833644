.root {
    margin-bottom: 16px;
    p {
        margin: 0;
    }
}

.title {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    color: #000000;
    @media screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 40px;
    }
}

.introduction {
    margin-top: 16px;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    &.responsive {
        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
