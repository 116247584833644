.videoCarousel {
    :global {
        .swiper-container {
            height: 100%;
            overflow: visible;

            .swiper-wrapper {
                transition-timing-function: linear;
                user-select: none;
                .swiper-slide {
                    transition-duration: 400ms !important;
                    width: auto;
                    position: relative;
                }
            }
        }
        .swiper-button-disabled {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.5s ease-in-out;
        }
    }
}
.videoCarousel {
    &Container {
        position: relative;
    }
    position: relative;

    &ButtonNext,
    &ButtonPrev {
        align-items: center;
        background-color: #b2fce4;
        border-radius: 12px;
        bottom: 50%;
        cursor: pointer;
        display: flex;

        justify-content: center;
        opacity: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity 0.5s ease-in-out;
        visibility: visible;
        height: 64px;
        width: 64px;
        z-index: 2;
        svg {
            height: 20px;
            width: 20px;
            font-weight: bold;
        }
        path {
            stroke: #000;
            stroke-width: 1.5px;
        }
        &:active {
            background-color: rgb(178 252 228 / 0.8);
        }
        @media screen and(max-width:1338px) {
            display: flex;
        }
        @media screen and (max-width: 786px) {
            height: 56px;
            width: 56px;
            svg {
                height: 16px;
                width: 16px;
            }
        }
    }

    &ButtonNext {
        right: -35px;
    }
    &ButtonPrev {
        left: -35px;
        svg {
            transform: rotate(180deg);
        }
    }
    &QuoteContainer {
        margin: 40px auto 64px auto;
        width: fit-content;
        overflow: hidden;
        max-width: 100%;
        :global {
            .swiper-container {
                height: 154px;
            }
            .swiper-slide {
                max-width: 100%;
                width: 424px;
            }
        }
        &Divider {
            display: none;
        }
    }
    @media screen and(max-width: 786px) {
        &ButtonNext {
            right: -7px !important;
        }
        &ButtonPrev {
            left: -7px !important;
            svg {
                transform: rotate(180deg);
            }
        }
        &QuoteContainer {
            margin: 24px auto 0 auto;
            &Divider {
                display: block;
                width: 4px;
                height: 64px;
                background: #b2fce4;
                border-radius: 100px;
                margin: 0 auto 24px auto;
            }
        }
    }
}
.videoItem {
    position: relative;

    border-radius: 8px;
    &Container {
        width: 424px;
        height: 424px;
        border-radius: 8px;
    }
    &ThumbnailUrl {
        border-radius: inherit;
    }

    &:hover {
        cursor: pointer;
        :global {
            .image-hover-zoom {
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -o-transform: scale(1.1);
                -ms-transform: scale(1.1); /* IE 9 */
                transform: scale(1.1);
            }
        }
    }
    @media screen and(max-width:786px) {
        &Container {
            width: 255px;
            height: 255px;
        }
    }
}
