.businessArticleHeroInfo {
    &:not(.simple) {
        background-color: #eff4fb;
        border-radius: 24px;
        bottom: 12px;
        height: auto;
        left: 72px; // 60px + 12px: Hero padding + component left position
        max-width: 100%;
        padding: 40px;
        position: absolute;
        width: 648px;
        z-index: 200;
    }
    .categories {
        margin-bottom: 24px;
    }
    .title {
        font-size: 40px;
        font-weight: bold;
        line-height: 52px;
        &.light {
            color: #fff;
        }
    }
    .description {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        margin-top: 8px;
    }
    .readMoreUrl {
        background-color: #b2fce4;
        border-radius: 50px;
        color: black;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        line-height: 24px;
        margin-top: 32px;
        padding: 12px 24px;
        text-align: center;
        text-decoration: none;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        width: fit-content;

        &:visited {
            color: #000000;
        }

        svg {
            margin-left: 12px;
            vertical-align: middle;

            path {
                stroke: #000000;
            }
        }

        &:hover {
            background-color: #000000;
            color: #ffffff;
            svg {
                path {
                    stroke: #ffffff;
                }
            }
        }
    }
    @media screen and(max-width:786px) {
        // left: 15px;
        padding: 24px 16px;
        right: 15px;
        width: calc(100% - 148px);
        .categories {
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 14px;
        }
        .title {
            font-size: 32px;
            line-height: 40px;
        }
        .description {
            font-size: 14px;
            line-height: 22px;
        }
        .readMoreUrl {
            margin-top: 20px;
            margin-top: 24px;
            width: 100%;
        }
    }
    @media screen and(max-width:768px) {
        left: 0;
        margin: -10% 15px 38px 15px;
        position: relative;
        width: auto;
        z-index: 200;

        &:not(.simple) {
            @media screen and(max-width:786px) {
                left: 0;
                position: relative;
                width: calc(100% - 30px);
            }
        }
    }
}
