.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}
