@import '../../styles/styles';

.videoList {
    margin: 0 auto;
    padding: 128px 0px;

    display: grid;
    grid-template-columns: minmax(320px, 720px) 1fr;
    align-self: start;
    justify-content: space-between;
    position: relative;

    &::before {
        position: absolute;
        left: 50%;
        width: 100vw;
        top: 0;
        bottom: 0;

        transform: translateX(-50%);

        content: '';
        background-color: #000;
        border-radius: 24px;

        z-index: -1;
    }
}

.videoListContainer {
    max-width: 536px;
    margin-left: auto;
    min-width: 80%;
}
.videoListHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 64px;
    column-gap: 20px;
    grid-column: span 2;
}

.videoListDescriptionText {
    color: white;
    margin: 0;
    font-size: 20px;
    line-height: 28px;
}
.videoListHeaderText {
    color: white;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    margin: 0;
    max-width: 488px;
    margin-top: auto;
}
.videoListHeaderTextWatchAll {
    position: relative;
    white-space: nowrap;
    font-weight: bold;
    padding: 12px 24px;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    z-index: 1;
    transition: background-color 0.3s ease-in;
    background-color: #b2fce4;
    height: fit-content;
    margin-top: auto;

    @include buttons-style(black, black, white);
    svg {
        margin-left: 12px;
        width: 16px;
        height: 16px;
        transition: transform 0.3s;
    }
}
.linkWatchAllVideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.videoItem {
    padding: 40px 0;
    border-bottom: 1px solid #fff;
    position: relative;
    &:last-of-type {
        padding-bottom: 0px !important;
        border-bottom: 0;
    }
    &:first-child {
        padding-top: 0 !important;
    }
    &Link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.videoItemContainer {
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto 32px;
    align-items: flex-start;
    margin-top: 16px;
    position: relative;
    cursor: pointer;

    svg {
        width: 32px;
        height: 32px;
        path {
            stroke: #fff;
        }
    }
}
.videoItemImageBrand {
    max-width: 168px;
    max-height: 32px;
}
.videoItemTitle {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: white;
}
@media only screen and (max-width: 1366px) {
    .videoList {
        padding: 128px 0px;
    }
}
@media screen and (max-width: 1365px) {
    .videoListContainer {
        width: fit-content;
        margin: 0 auto 32px auto;
    }
    .videoBlock {
        margin-right: 30px;
    }
}
@media screen and(max-width:1112px) {
    .videoList {
        padding: 64px 0px;
        display: flex;
        flex-direction: column;
    }
    .videoListContainer {
        margin: 0 auto;
        margin-top: 32px;
    }
    .videoBlock {
        margin: 0 auto;
        width: fit-content;
        max-width: 100%;
        width: 630px;
        max-width: 100%;
    }
    .videoListHeader {
        max-width: 630px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        .videoListHeaderText {
            font-size: 32px;
            line-height: 40px;
        }
        .videoListDescriptionText {
            font-size: 16px;
            line-height: 24px;
        }
        .videoListHeader {
            margin-bottom: 32px;
        }
    }
    .videoItem {
        padding: 25px 0;
    }
}

@media screen and(max-width:576px) {
    .videoList {
        padding: 65px 0px 144px 0px;
    }
    .videoListHeader {
        margin-bottom: 0;
    }
    .videoListDescriptionText {
        margin-bottom: 32px;
    }
    .videoListHeaderText {
        font-size: 32px;
        line-height: 40px;
    }
    .videoListContainer {
        margin: 0 auto;
        margin-top: 32px;
    }
    .videoItemContainer {
        svg {
            width: 22px;
            height: 22px;
        }
    }

    .videoItemTitle {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px !important;
    }
    .videoListHeaderTextWatchAll {
        position: absolute;
        bottom: 64px;
        width: auto;
        left: 15px;
        right: 15px;
        justify-content: center;
    }
}
