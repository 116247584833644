.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin: 16px 0;

    &.primary {
        grid-template-rows: 1fr 1fr;
        grid-template-rows: 1fr;

        > div:nth-child(3n - 2) {
            grid-column: span 3;
            grid-row: span 2;
            height: 100%;
        }
    }

    @media only screen and(max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
        &.primary {
            grid-template-columns: repeat(3, 1fr);
            > div:nth-child(3n - 2) {
                grid-column: span 2;
                grid-row: span 2;
            }
        }
    }
    @media only screen and(max-width: 768px) {
        gap: 16px;
    }
    @media only screen and(max-width: 576px) {
        grid-template-columns: 1fr;

        &.primary {
            grid-template-columns: 1fr;
            > div:nth-child(3n - 2) {
                grid-column: span 1;
                grid-row: span 1;
            }
        }
    }
}
