.modal {
    position: fixed;
    inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    z-index: 999;
    padding: 40px 20px 20px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    background: #ffffff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 40px;
    width: 100%;
    max-width: 696px;
    margin-bottom: 32px;
    max-height: 100%;
    overflow: auto;
    @media screen and (max-width: 992px) {
        padding: 24px;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #1d1d1d;
}

.closeButton {
    cursor: pointer;
}

.footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
