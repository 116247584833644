.container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    margin: 20px 0;

    .priceText {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        margin-right: 16px;
    }

    .badge {
        width: 184px;
        height: 64px;
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &.reversed {
        flex-direction: row-reverse;
        justify-content: flex-end;
        .priceText {
            margin-left: 16px;
            margin-right: 0;
        }
    }

    @media only screen and(max-width: 768px) {
        margin: 8px 0;

        .priceText {
            font-size: 16px;
            line-height: 24px;
            margin-right: 12px;
        }

        .badge {
            width: 103px;
            height: 36px;
        }

        &.reversed {
            .priceText {
                margin-left: 12px;
            }
        }
    }
}
