.breadcrumbContainer {
    padding: 32px 0px 32px 0;

    width: 100%;
}

.breadcrumbContainer.light {
    a {
        color: white;
    }
    .separator path {
        stroke: #fff;
    }
}
.breadcrumbContainer.dark {
    a {
        color: #000;
    }
    .separator path {
        stroke: #000;
    }
}

.container {
    margin: 0;
    padding: 0;
    width: 20em;
}

.breadcrumb {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    padding-left: 0;

    .separator:first-child {
        display: none;
    }
    li {
        display: inline;
        a {
            color: white;
            font-weight: 700;

            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            text-decoration: none;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    li:last-child {
        a {
            font-weight: 400;
        }
    }
    .separator {
        height: 12px;
        width: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin: 0 12px;
    }
}

@media screen and (max-width: 768px) {
    .breadcrumbContainer {
        padding: 16px 0;
        .separator svg {
            margin: 0;
            margin-right: 8px;
            transform: rotate(180deg);
        }

        li {
            margin: 0 !important;
            visibility: hidden;
            display: none !important;
        }

        li:nth-last-child(-n + 4) {
            display: inline !important;
            visibility: visible;
            a {
                font-weight: 700;
            }
        }
        li:nth-last-child(-n + 2) {
            visibility: hidden;
            display: none !important;
        }
    }
}
