.root {
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    position: relative;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 12px;
    isolation: isolate;
}

.content {
    display: flex;
    height: 100%;
    line-height: 0;
    padding: 16px 26px;
    transition: 300ms;
}

.imageContainer {
    position: relative;
    padding-top: 86px;
}

.image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}

.checkbox {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
}

.pagination {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}
.swiperContainer {
    height: 100%;
    overflow: hidden;
    max-width: 100%;
    display: flex;
    flex-direction: column-reverse;
    :global(.swiper-pagination-bullets) {
        //position: absolute;
        text-align: center;
        transition: 0.3s opacity;
        transform: translate3d(0, 0, 0);
        z-index: 10;
        bottom: 10px;
        left: 0;
        width: 100%;
        line-height: 0;
        margin-top: 8px;
        margin-bottom: 8px;
        :global(.swiper-pagination-bullet) {
            width: 8px;
            height: 8px;
            display: inline-block;
            //opacity: 1;
            cursor: pointer;
            margin: 0 2px;
            box-sizing: border-box;
            border-width: 1px;
            border-style: solid;
            border-radius: 50%;
            border-color: transparent;
            &.black {
                background: #000000;
                border-color: #000000;
            }
            &.mint {
                background: #b2fce4;
                border-color: #b2fce4;
            }
            &.white {
                background: #ffffff;
                border-color: #000000;
            }
        }
    }
}
