@import '../../styles/_styles.scss';
.seriesCollectionBlock {
    padding: 64px 0;
    position: relative;
    z-index: 0;

    &OrcaPastel {
        position: absolute;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        margin-left: calc(50% - 50vw);
        background-color: #eff4fb;
        z-index: -1;
    }
}

.seriesHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 1440px;
    max-width: 100%;
    margin: 0 auto;
}

.title {
    margin: 0;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 8px;
}
.description {
    margin: 0;
    font-size: 20px;
    line-height: 28px;
}

.btnViewAll {
    white-space: nowrap;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    background-color: transparent;
    border-radius: 50px;
    width: fit-content;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    background-color: #b2fce4;
    svg {
        margin-left: 12px;
    }
    @include buttons-style(black, white, black);
}
@media screen and(max-width:768px) {
    .seriesCollectionBlock {
        padding: 32px 0;
        padding-bottom: 80px;
    }
    .seriesHeader {
        padding: 0;
    }
    .title {
        font-size: 32px;
        line-height: 40px;
    }
    .description {
        font-size: 16px;
        line-height: 24px;
    }
    .btnViewAll {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        bottom: 32px;
        margin-top: 32px;
    }
}
