$business-resouce-training-block-prefix: businessResourceTrainingBlock;

.#{$business-resouce-training-block-prefix}Container {
    max-width: 100%;
    
    margin-left: auto;
    margin-right: auto;

    .#{$business-resouce-training-block-prefix} {
        &Title {
            font-size: 56px;
            font-weight: 700;
            line-height: 64px;
            margin-bottom: 16px;
        }
        &Description {
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            margin-bottom: 64px;
            max-width: 984px;
        }
        &Items {
            column-gap: 24px;
            row-gap: 24px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media only screen and (max-width: 979px) {
        padding: 32px 15px;

        .#{$business-resouce-training-block-prefix} {
            &Title {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 8px;
            }
            &Description {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 32px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .#{$business-resouce-training-block-prefix} {
            &Items {
                row-gap: 16px;
                grid-template-columns: 1fr;
            }
        }
    }
}
