$video-card-prefix: videoCard;

.#{$video-card-prefix} {
    background-attachment: scroll !important;
    background-color: #000;
    position: relative;
    margin-bottom: 120px;
    @media screen and (max-width: 786px) {
        margin-bottom: 64px;
    }
    &Container {
        align-items: center;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;

        padding-bottom: 40px;

        .#{$video-card-prefix} {
            &Info {
                align-items: flex-start;
                justify-content: center;
                &.right {
                    margin-left: auto;
                }
                .info {
                    &ImageBrand {
                        max-width: 144px;
                        height: auto;
                        max-height: 40px;
                        margin-bottom: 32px;
                        mix-blend-mode: screen;
                    }

                    &Title {
                        font-size: 56px;
                        font-weight: 700;
                        line-height: 64px;
                        margin-bottom: 24px;
                        max-width: 536px;
                        color: #fff;
                    }
                    &Description {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 32px;
                        margin-bottom: 32px;
                        max-width: 536px;
                        color: #fff;
                    }
                    &CTA {
                        display: block;
                        background: #b2fce4;
                        border-radius: 100px;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        padding: 16px 24px;
                        text-align: center;
                        text-decoration: none;
                        transition: background-color 0.3s ease-in-out;
                        width: fit-content;
                        white-space: nowrap;
                        &:visited {
                            color: #000000;
                        }
                        svg {
                            margin-left: 14.5px;
                            vertical-align: middle;

                            path {
                                stroke: #000000;
                            }
                        }

                        &:hover {
                            background-color: #fff;
                            color: #000;
                            svg {
                                path {
                                    stroke: #000;
                                }
                            }
                        }
                    }
                }
            }
            &MediaContainer {
                height: 616px;
                max-height: 100%;
                max-width: 100%;
                position: relative;
                transition: width 0.3s ease, height 0.3s ease;
                width: 424px;

                &.left {
                    margin-left: auto;
                    margin-right: 192px;
                }
                &.right {
                    margin-right: auto;
                    margin-left: 192px;
                }

                .#{$video-card-prefix}Media {
                    height: calc(100% - 64px);
                    justify-content: center;
                    position: relative;
                    width: 100%;

                    .media {
                        &Image {
                            &Wrapper {
                                border-radius: 24px;
                                height: 100%;
                                overflow: hidden;
                                position: absolute;
                                top: 64px;
                                width: 100%;
                                z-index: 2;
                                opacity: 1 !important;
                                &.left {
                                    left: 64px;
                                    right: 0;
                                }

                                &.right {
                                    right: 64px;
                                }
                                &:hover {
                                    :global {
                                        .image-hover-zoom {
                                            -webkit-transform: scale(1.1);
                                            -moz-transform: scale(1.1);
                                            -o-transform: scale(1.1);
                                            -ms-transform: scale(1.1); /* IE 9 */
                                            transform: scale(1.1);
                                        }
                                    }
                                }
                            }
                            &Box {
                                background: transparent;
                                border-radius: inherit;
                                height: 100%;
                                left: 0;
                                position: absolute;
                                top: 0;
                                width: 100%;
                            }
                            &Main {
                                position: relative;
                                padding-top: 130.189%;
                                opacity: 1;
                                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
                                transform-style: preserve-3d;
                            }
                            &Link {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                z-index: 4;
                            }
                            &Container {
                                position: relative;
                                &ButtonPlay {
                                    width: 120px;
                                    height: 120px;
                                    cursor: pointer;
                                    z-index: 1;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 50%;
                                    background: rgba(0, 0, 0, 0.5);
                                    backdrop-filter: blur(8px);
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    svg {
                                        transform: translateX(5px);
                                    }
                                }
                            }
                        }
                        &Background {
                            background-color: transparent;
                            border: 4px solid;
                            border-radius: 24px;
                            height: 100%;
                            width: auto;
                            z-index: 1;
                            &.left {
                                margin-left: 0;
                            }

                            &.right {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        @media only screen and(max-width: 979px) {
            gap: 32px;
            .#{$video-card-prefix} {
                &Info {
                    .info {
                        &Title {
                            font-size: 32px;
                            line-height: 40px;
                            margin-bottom: 8px;
                        }
                        &Description {
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 32px;
                        }
                    }
                }
                &MediaContainer {
                    height: 375px;
                    width: 281px;
                    .#{$video-card-prefix}Media {
                        height: 100%;
                        .media {
                            &Image {
                                &Wrapper {
                                    height: calc(100% - 64px);
                                    top: 32px;
                                    width: calc(100% + 32px);
                                    &.left {
                                        left: 32px;
                                        right: 0;
                                    }

                                    &.right {
                                        right: 32px;
                                    }
                                }
                                &Main {
                                    padding-top: 99.361%;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media only screen and(max-width: 768px) {
            grid-template-columns: 1fr;
            padding-bottom: 32px;
            .#{$video-card-prefix} {
                &Info {
                    &.right {
                        order: 1;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    .info {
                        &Title {
                            margin-bottom: 8px;
                        }
                        &CTA {
                            display: block;
                            width: 100%;
                        }
                    }
                }
                &MediaContainer {
                    margin-left: auto !important;
                    margin-right: auto !important;
                    max-height: 375px;
                    max-width: 281px;
                    padding-top: min(92%, 375px);
                    width: 100%;
                    &.right {
                        order: 0;
                        margin-right: 0 !important;
                    }
                    &.left {
                        margin-left: 0 !important;
                    }
                    .#{$video-card-prefix}Media {
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        .mediaImageContainerButtonPlay {
                            width: 80px;
                            height: 80px;

                            svg {
                                transform: translateX(5px);
                                width: 35px;
                                height: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
}
