.copyIcon {
  opacity: 1;
  transition: opacity 0.3s ease;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  height: 32px;
  justify-content: center;
  position: relative;
  width: 32px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.tooltip {
  background-color: black;
  border-radius: 10px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  opacity: 0;
  padding: 8px 8px;
  transition-duration: 0.5s;
  transition-property: opacity, visibility;
  transition-timing-function: ease;
  visibility: hidden;
  position: relative;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  .tooltipArrow {
    height: 10px;
    width: 10px;

    &::before {
      background: #000;
      border-radius: 2px;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateX(0px) rotate(45deg);
      transform-origin: center;
      width: 100%;
      z-index: -1;
    }
  }

  &[data-popper-placement='top'] {
    margin-bottom: 10px;

    .tooltipArrow {
      bottom: 0;
      &::before {
        margin-top: 5px;
      }
    }
  }

  &[data-popper-placement='bottom'] {
    margin-top: 10px;

    .tooltipArrow {
      top: 0;
      &::before {
        margin-top: -5px;
      }
    }
  }
}
