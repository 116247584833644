.unorderedHighlightListContainer {
    padding: 40px;
    border-radius: 24px;
    width: 696px;
    margin: 0px auto 44px auto;
    max-width: 100%;
    a {
        color: #3375c9;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.unorderedHighlightHeading {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 32px !important;
}

.unorderedHighlightList {
    list-style: disc;
    padding-left: 26px;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
}

.unorderedHighlightItem {
    margin-bottom: 32px;
    &:last-child {
        margin-bottom: 0;
    }
}

@media screen and(max-width: 554px) {
    .unorderedHighlightListContainer {
        width: 100%;
        padding: 32px 15px;
    }
    .unorderedHighlightList {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }
    .unorderedHighlightHeading {
        font-size: 24px;
        line-height: 32px;
    }
}

.unorderedHighlightList.PLUM {
    list-style: none;
    counter-reset: custom-counter;
    margin: 0;
    padding: 0;
}
.unorderedHighlightItem.PLUM {
    counter-increment: custom-counter;
    display: -moz-box;
    margin-bottom: 24px;
    border-bottom: 0.5px dotted black;
    &::before {
        content: counter(custom-counter);
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
    }
}
.unorderedHighlightItem.PLUM:last-child {
    margin-bottom: 0px;
    border: none;
}
