.root {
    border: 0.5px solid #cccccc;
    border-radius: 12px;
    padding: 17px 24px 13px 24px;
    gap: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 300ms;
    &:hover {
        background: #e5e5e5;
    }
}
