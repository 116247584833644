.root {
    display: inline-flex;
    gap: 8px;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
        &:hover {
            cursor: not-allowed;
        }
    }
}

.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000;
    &.strong {
        font-weight: 700;
    }
}

.icon {
    width: 16px;
    height: 16px;
    svg {
        width: 16px;
        height: 16px;
    }
}
