.container {
    border-radius: 12px;
    height: 128px;
    overflow: hidden;
    position: relative;

    width: 100%;

    &:hover {
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    }

    &.bordered {
        border: 1px solid #e5e5e5;
    }

    &.large {
        height: 300px;
        margin: 16px 0;
    }

    @media only screen and(max-width: 768px) {
        height: 150px;
        &.large {
            height: 150px;
        }
    }

    .box {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;

        img {
            height: auto;
            max-width: 52%;
            max-height: 84px;
        }
    }

    .downloadContainer {
        position: absolute;
        right: 8px;
        top: 8px;

        .tooltip {
            background-color: black;
            border-radius: 12px;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            opacity: 0;
            padding: 14px 16px;
            transition: opacity 0.3s ease;
            user-select: none;

            .tooltipArrow {
                height: 12px;
                width: 12px;

                &::before {
                    background: #000;
                    border-radius: 2px;
                    content: '';
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    transform: translateX(0px) rotate(45deg);
                    transform-origin: center;
                    transition: transform 0.2s ease-out, visibility 0.2s ease-out;
                    width: 100%;
                    z-index: -1;
                }
            }

            &[data-popper-placement='top'] {
                margin-bottom: 18px;

                .tooltipArrow {
                    bottom: 0;
                    &::before {
                        margin-top: 5px;
                    }
                }
            }

            &[data-popper-placement='bottom'] {
                margin-top: 18px;

                .tooltipArrow {
                    top: 0;
                    &::before {
                        margin-top: -5px;
                    }
                }
            }

            span {
                border: 1px solid #4d4d4d;
                border-radius: 8px;
                padding: 3px 8px;
                &:not(:last-child) {
                    margin-right: 4px;
                }
            }
        }
        &:hover {
            .tooltip {
                opacity: 1;
            }
        }
    }

    .download {
        align-items: center;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 100px;
        cursor: pointer;
        display: flex;
        height: 24px;
        justify-content: center;
        transition: box-shadow 0.3s ease-in-out;
        width: 24px;

        &:hover {
            box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);

            .tooltip {
                display: block;
            }
        }
    }
}
