.root {
    margin-top: 24px;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

.section {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}

.previewContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.previewBlock {
}

.previewTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.previewImage {
    margin-top: 8px;
    width: 100%;
}

.action {
    display: flex;
    align-items: baseline;
    gap: 24px;
    @media screen and (max-width: 576px) {
        flex-direction: column;
        gap: 16px;
    }
}

.mobileFullWidth {
    @media screen and (max-width: 576px) {
        width: 100%;
        justify-content: center;
    }
}
