$business-resouce-block-prefix: businessResourceBlock;

.#{$business-resouce-block-prefix} {
    &Container {
        align-items: center;
        display: grid;
        gap: 136px;
        grid-template-columns: repeat(2, 1fr);

        margin-left: auto;
        margin-right: auto;

        max-width: 100%;

        padding: 64px 0px;

        .#{$business-resouce-block-prefix} {
            &Info {
                align-items: flex-start;
                justify-content: center;

                .info {
                    &Title {
                        font-size: 56px;
                        font-weight: 700;
                        line-height: 64px;
                        margin-bottom: 16px;
                        max-width: 536px;
                    }
                    &Description {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 32px;
                        margin-bottom: 32px;
                        max-width: 536px;
                    }
                    &CTA {
                        background: #b2fce4;
                        border-radius: 100px;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        padding: 16px 24px;
                        text-align: center;
                        text-decoration: none;
                        transition: background-color 0.3s ease-in-out;
                        width: fit-content;
                        &:visited {
                            color: #000000;
                        }
                        svg {
                            margin-left: 14.5px;
                            vertical-align: middle;

                            path {
                                stroke: #000000;
                            }
                        }

                        &:hover {
                            background-color: #000000;
                            color: #ffffff;
                            svg {
                                path {
                                    stroke: #ffffff;
                                }
                            }
                        }
                    }
                }
            }
            &MediaContainer {
                height: 601px;
                justify-content: center;
                max-height: 100%;
                max-width: 100%;
                position: relative;
                transition: width 0.3s ease, height 0.3s ease;
                width: 536px;

                .#{$business-resouce-block-prefix}Media {
                    height: 100%;
                    justify-content: center;
                    position: relative;
                    width: 100%;

                    .media {
                        &Image {
                            &Wrapper {
                                border-radius: 24px;
                                height: calc(100% - 128px);

                                position: absolute;
                                top: 64px;
                                width: calc(100% - 64px);

                                .box {
                                    .main {
                                        background-position: 50%;
                                        background-repeat: no-repeat;
                                        background-size: cover;
                                        height: 100%;
                                        transition: transform 0.3s;
                                        width: 100%;
                                    }
                                }

                                &.left {
                                    left: unset;
                                    right: 0;
                                }

                                &.right {
                                    left: 0;
                                    right: unset;
                                }
                            }
                            &Box {
                                background: transparent;
                                border-radius: inherit;
                                height: 100%;
                                left: 0;

                                position: absolute;
                                top: 0;
                                transform: rotate(0);
                                transition: transform 0.3s;
                                width: 100%;
                            }
                            &Main {
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;
                                border-radius: 24px;
                                height: 100%;
                                left: 0;
                                overflow: hidden;
                                position: absolute;
                                right: 0;
                                transition: transform 0.3s;

                                width: 100%;
                                z-index: 1;
                            }
                        }
                        &Background {
                            background-color: #b2fce4;
                            border-radius: 24px;
                            height: 100%;
                            width: auto;

                            &.left {
                                margin-left: 0;
                                margin-right: 64px;
                            }

                            &.right {
                                margin-left: 64px;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        @media only screen and(max-width: 979px) {
            gap: 32px;
            .#{$business-resouce-block-prefix} {
                &Info {
                    .info {
                        &Title {
                            font-size: 32px;
                            line-height: 40px;
                            margin-bottom: 8px;
                        }
                        &Description {
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 32px;
                        }
                    }
                }
                &MediaContainer {
                    height: 375px;
                    width: 345px;
                    .#{$business-resouce-block-prefix}Media {
                        .media {
                            &Image {
                                &Wrapper {
                                    height: calc(100% - 64px);
                                    top: 32px;
                                    width: calc(100% - 32px);
                                }
                            }
                        }
                    }
                }
            }
        }

        @media only screen and(max-width: 768px) {
            grid-template-columns: 1fr;
            padding: 32px 15px;
            .#{$business-resouce-block-prefix} {
                &Info {
                    &.right {
                        order: 1;
                    }
                    .info {
                        &CTA {
                            display: block;
                            width: 100%;
                        }
                    }
                }
                &MediaContainer {
                    height: 0;
                    margin-left: auto;
                    margin-right: auto;
                    max-height: 375px;
                    max-width: 345px;
                    padding-top: min(92%, 375px);
                    width: 100%;
                    &.right {
                        order: 0;
                    }
                    .#{$business-resouce-block-prefix}Media {
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}
