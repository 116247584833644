@mixin normalizeButton {
}

.root {
    cursor: pointer;
    border: 1px solid #e5e5e5;
    padding: 5px 16px 3px 16px;
    background: #fff;
    color: #000;
    border-radius: 100px;
    transition: 300ms;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    &.selected {
        border-color: #000;
        background: #000000;
        color: #ffffff;
        &:hover {
            background: rgb(108 108 108);
            border-color: rgb(108 108 108);
        }
    }
    &:hover {
        background: #e5e5e5;
    }
}
