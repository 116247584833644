@import '../../styles/_styles.scss';
.learningCard {
    position: relative;
    border-radius: 12px;
    max-height: 540px;
    width: 424px;
    cursor: pointer;
    :global {
        .image-zoom {
            border-radius: 12px;
        }
    }
    &:hover {
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
        transition: box-shadow 0.3s ease;
        :global {
            .image-hover-zoom {
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -o-transform: scale(1.1);
                -ms-transform: scale(1.1); /* IE 9 */
                transform: scale(1.1);
            }
        }
    }
}

.learningCardContainer {
    color: white;
    max-width: 360px;
    position: absolute;
    top: 32px;
    left: 32px;
    z-index: 1;
    height: 476px;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.25) 100%);
    border-radius: inherit;
}

.quantity {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
}
.title {
    margin: 0;
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
    margin-bottom: 24px;
}
.description {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
}
.threeLinesLimit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.btnReadAll {
    color: white;
    white-space: nowrap;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding: 11px 24px;
    background-color: transparent;
    border-radius: 50px;
    width: fit-content;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    svg {
        margin-left: 12px;
    }
    @include buttons-style(white, black, white, 1px solid #fff);

    position: absolute;
    bottom: 0;
}
.learningUrl {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
}
@media screen and(max-width:768px) {
    .learningCard {
        width: 300px;
        height: 375px;
        :global {
            .image-zoom {
                height: 375px;
            }
        }
    }
    .learningCardContainer {
        top: 16px;
        left: 16px;
        width: 268px;
        height: 343px;
    }
    .title {
        font-size: 40px;
        line-height: 52px;
        margin-bottom: 16px;
    }
    .description {
        font-size: 20px;
        line-height: 28px;
    }
    .btnReadAll {
        width: 100%;
    }
}
