.image {
    width: 100%;
    max-width: 100%;
}

.root {
    gap: 16px;
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
}

.leftBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.clickable {
    cursor: pointer;
}

.alignCenter {
    align-items: center;
}
