.businessTextWrapContainer {
    max-width: 100%;
    width: 648px;
    margin: 0px auto 44px auto;
}

.businessTextWrap {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #333333;

    &.light {
        color: #fff;

        * {
            color: #fff !important;
        }

        a {
            color: #3375c9 !important;
        }
    }

    p {
        margin-top: 0;
        margin-bottom: 32px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    a {
        color: #000 !important;
        text-decoration: underline !important;
        &:hover {
            text-decoration: underline;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        text-align: left;
        margin: 0;
        margin-bottom: 16px;
    }
}
@media screen and (max-width: 768px) {
    .businessTextWrapContainer {
        padding: 0 15px;
    }
    .businessTextWrap {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 24px;
        }
    }
}
