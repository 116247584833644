.container {
    font-size: 24px;
    line-height: 32px;
    position: relative;
    margin: 32px 0;

    @media only screen and(max-width: 768px) {
        margin: 16px 0;
    }
}

.title {
    font-weight: 700;
    margin-bottom: 25px;

    @media only screen and(max-width: 768px) {
        margin-bottom: 32px;
    }
}

.item {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .number {
        align-items: center;
        background: #b2fce4;
        border-radius: 100px;
        display: flex;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 700;
        height: 24px;
        justify-content: center;
        line-height: 16px;
        margin-right: 15px;
        padding: 2px 2px 0 0;
        width: 24px;
    }

    &:not(:last-child) {
        margin-bottom: 32px;
    }

    &:first-child {
        .number {
            align-self: flex-start;
        }
    }

    @media only screen and(max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        .number {
            margin-bottom: 32px;
        }
    }
}

.content {
    & > * {
        font-size: inherit !important;
        text-align: inherit !important;
        line-height: inherit !important;
        margin-bottom: 0;
        margin-top: 0;
    }
}
