.businessArticleQuoteContainer {
    width: 648px;
    max-width: 100%;
    margin: 0 auto 44px auto;
    position: relative;
    a {
        color: #3375c9;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
.businessArticleQuoteBackground {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #b2fce4;
    border-radius: 1000px;
    height: 100%;

    z-index: -1;
    &.small {
        right: 48%;
    }
    &.medium {
        right: 40%;
    }
    &.large {
        right: 20%;
    }
    &.full {
        right: 0;
    }
}
.businessArticleQuoteBox{
    padding: 32px 0 32px 32px;
    &.light {
        * {
            color: #ffffff !important;
        }
    }
}
.businessArticleQuote {

    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    p {
        margin: 0;
    }
}
.businessArticleAuthorName {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
}
@media screen and (max-width: 768px) {
    .businessArticleQuoteContainer {
        padding: 0 15px;
    }
    .businessArticleQuoteBackground {
        left: 15px;
        max-width: 648px;
        &.small {
            width: 200px;
        }
        &.medium {
            width: 250px;
        }
        &.large {
            width: 345px;
        }
        &.full {
            right: 15px;
        }
    }
    .businessArticleQuote {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 4px;
    }
    .businessArticleAuthorName {
        font-size: 14px;
        line-height: 22px;
    }
}
