.container {
    * {
        box-sizing: border-box;
    }
    position: relative;
    width: 100%;
    margin: 20px 0;

    .title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .mask {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
        z-index: 1;
    }

    .sliderContainer {
        border-radius: 16px;
        height: 320px;
        overflow: hidden;
        position: relative;
        width: 100%;

        @media only screen and(max-width: 768px) {
            height: 200px;
        }
    }

    .do,
    .dont {
        border: 2px solid #fff;
        border-radius: 50%;
        bottom: 16px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        height: 40px;
        line-height: 24px;
        position: absolute;
        width: 40px;
        z-index: 1;

        span {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    .do {
        background-color: #3375c9;
        right: 16px;
        span {
            margin-right: 16px;
            right: 100%;
        }

        &::before,
        &::after {
            background: #ffffff;
            border-radius: 5px;
            content: '';
            height: 20px;
            left: 61%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 2px;
        }
        &::after {
            height: 10px;
            left: 33%;
            top: 58%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    .dont {
        background-color: #f4643b;
        left: 16px;
        span {
            left: 100%;
            margin-left: 16px;
        }
        &::before,
        &::after {
            background: #ffffff;
            border-radius: 5px;
            content: '';
            height: 18px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 2px;
        }
        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    .imgCompSlider {
        backdrop-filter: blur(4px);
        background: rgba(0, 0, 0, 0.74);
        border: 2px solid #ffffff;
        border-radius: 50%;
        cursor: ew-resize;
        height: 32px;
        position: absolute;
        /* set the appearance of the slider: */
        width: 32px;
        z-index: 9;

        > div {
            height: 100%;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 0;

            &::before,
            &::after {
                border: 1px solid #fff;
                content: ' ';
                height: 144px !important;
                position: absolute;
                width: 0;
            }

            &::before {
                top: 30px;
            }
            &::after {
                bottom: 30px;
            }
        }

        &::before,
        &::after {
            content: '';
            height: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
        }

        &::after {
            border-bottom: 4px solid transparent;
            border-left: 4px solid #ffffff;
            border-top: 4px solid transparent;
            right: 7px;
        }
        &::before {
            border-bottom: 4px solid transparent;
            border-right: 4px solid #ffffff;
            border-top: 4px solid transparent;
            left: 7px;
        }
    }
}

.img {
    height: auto;
    overflow: hidden;
    position: absolute;
    width: auto;

    img {
        display: block;
        height: 320px;
        object-fit: cover;
        width: 100%;

        @media only screen and(max-width: 768px) {
            height: 200px;
        }
    }

    &.imgCompOverlay {
        box-sizing: content-box;
        opacity: 1;
        z-index: 1;
    }
}
