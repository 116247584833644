.numericalListContainer {
    padding: 40px;
    border-radius: 24px;
    width: 696px;
    margin: 0px auto 44px auto;
    max-width: 100%;
    a {
        color: #3375c9;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.numericalHeading {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 64px;
    
}

.numericalList {
    list-style: none;
    counter-reset: custom-counter;
    margin: 0;
    padding: 0;
}
.numericalWrapper {
    border-bottom: 0.5px dotted black;
    width: 100%;
}
.numericalTitle {
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}
.numericalDescription {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 40px;
}
.numericalItem {
    p {
        margin: 0;
    }
    margin-bottom: 40px;

    display: flex;

    counter-increment: custom-counter;
    display: -moz-box;

    &::before {
        content: counter(custom-counter);
        font-weight: 700;
        margin-right: 24px;
        font-size: 56px;
        line-height: 56px;
    }
}
.numericalItem:last-child {
    margin-bottom: 0px;
    .numericalWrapper {
        border: none;
    }
    .numericalDescription {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .numericalListContainer {
        width: 100%;
        padding: 32px 15px;
    }
    .numericalList {
        font-size: 16px;
        line-height: 24px;
    }
    .numericalHeading {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 32px;
    }
    .numericalDescription {
        margin-bottom: 24px;
    }
    .numericalItem {
        display: block;
        margin-bottom: 24px;
        &::before {
            font-size: 40px;
            line-height: 52px;
        }
    }
    .numericalTitle {
        margin-top: 8px;
        p {
            font-size: 20px;
            line-height: 28px;
        }
    }
    .numericalDescription p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}
