.root {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    p {
        margin: 0;
    }
    @media screen and (max-width: 768px) {
        margin-top: 16px;
    }
}
.tab {
    margin-top: 12px;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
        margin-top: 8px;
        margin-bottom: 16px;
    }
}

.action {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
    gap: 8px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
        margin-bottom: 8px;
        align-items: stretch;
    }
}

.buttonResponsive {
    @media screen and (max-width: 768px) {
        width: 100% !important;
    }
}

.lock {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    padding: 1rem;
    transition: border-bottom 300ms, border-radius 300ms;
    //border-bottom: solid 1px #f5f5f5;
    //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: white;
}

.buttonContainer {
    //width: 100%;
    height: 48px;
}

.lockedButton {
    //box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.leftAction {
    display: inline-flex;
    gap: 8px;
    @media screen and (max-width: 768px) {
        gap: 16px;
        flex-direction: column;
        display: flex;
        width: 100%;
    }
}

.dropdownButton {
    min-width: 250px;
}

.chip {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 4px;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
        margin-top: 0;
        margin-bottom: 16px;
    }
}
