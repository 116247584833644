.root {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 7px 16px 3px;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    background: #eff4fb;
    border-radius: 100px;
    cursor: pointer;
    svg {
        cursor: pointer;
    }
}

.icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: -4px;
}
