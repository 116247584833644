@import 'styles/styles';
.root {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    //gap: 19px;
    &.showDivider {
        margin-top: 0;
    }
}

.titleContainer {
    display: flex;
    align-items: center;
    @media screen and (max-width: 992px) {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }
}

.title {
    flex-grow: 1;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
}
.button {
    margin-top: 24px;
}

.resourceContainer {
    margin-top: 16px;
    gap: 24px;
    display: flex;
    grid-column: 1 / 3;
}
.resource {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
    @media screen and (max-width: 992px) {
        grid-column: 1 / 2;
        grid-template-columns: minmax(0, 1fr) !important;
    }
}

.downloadButton {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 992px) {
        width: 100%;
        margin-top: 8px;
    }
}
.description {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    @media screen and (max-width: 992px) {
        margin-top: 8px;
    }
}
