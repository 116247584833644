.container {
    display: grid;
    gap: 64px 24px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 20px;

    @media only screen and(max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and(max-width: 992px) {
        gap: 32px 24px;
    }
    @media only screen and(max-width: 768px) {
        grid-template-columns: 1fr;
        margin-bottom: 16px;
    }
}
