@import '../../../styles/styles';
$business-resouce-related-item-prefix: businessResourceRelatedItem;

.#{$business-resouce-related-item-prefix} {
    &Container {
        border-radius: 16px;
        display: flex;
        height: 330px;
        overflow: hidden;
        padding: 32px;
        position: relative;
        width: 424px;

        &:hover {
            .#{$business-resouce-related-item-prefix} {
                &Background {
                    .box {
                        .main {
                            -webkit-transform: scale(1.1);
                            -moz-transform: scale(1.1);
                            -o-transform: scale(1.1);
                            -ms-transform: scale(1.1);
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        .#{$business-resouce-related-item-prefix} {
            &Background {
                border-radius: 16px;
                height: 100%;
                left: 0;
                overflow: hidden;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 0;
                &::after {
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 58.95%);
                    bottom: 0;
                    content: '';
                    height: 100%;
                    left: 0;
                    pointer-events: none;
                    position: absolute;
                    width: 100%;
                    z-index: 0;
                }
                .box {
                    border-radius: 16px;
                    height: 100%;
                    overflow: hidden;
                    width: 100%;
                    .main {
                        background-position: 50%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 100%;
                        transition: transform 0.3s;
                        width: 100%;
                    }
                }
            }
            &Content {
                margin-top: auto;
                z-index: 1;
                .content {
                    &Title {
                        color: #ffffff;
                        font-size: 40px;
                        font-weight: 700;
                        line-height: 52px;
                        margin-bottom: 16px;
                        margin-top: auto;
                    }
                    &CTA {
                        border: 1px solid #ffffff;
                        border-radius: 100px;
                        box-sizing: border-box;
                        color: #ffffff;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 22px;
                        margin-top: auto;
                        padding: 11px 24px;
                        text-decoration: none;
                        transition: background-color 0.3s ease-in-out;
                        width: fit-content;
                        word-break: break-all;
                        &:visited {
                            color: #ffffff;
                        }
                        svg {
                            margin-left: 8.5px;
                            vertical-align: middle;
                            path {
                                stroke: #ffffff;
                            }
                        }
                        @include buttons-style(white, black, white, 1px solid #e5e5e5);
                    }
                }
            }
        }

        @media only screen and (max-width: 979px) {
            height: 330px;
            width: 300px;

            .#{$business-resouce-related-item-prefix} {
                &Content {
                    width: 100%;
                    .content {
                        &Title {
                            font-size: 32px;
                            line-height: 40px;
                        }
                        &CTA {
                            display: block;
                            font-size: 16px;
                            line-height: 24px;
                            padding: 11px 24px;
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
