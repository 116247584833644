@import '../../styles/_styles.scss';
.businessButtonWrapper {
    margin: 20px auto 64px auto;

    display: block;
    box-sizing: border-box;

    &.mint {
        @include buttons-style(black, white, black);
    }
    &.plum {
        @include buttons-style(white, black, #b2fce4);
    }
    &.black {
        @include buttons-style(white, black, white, 1px solid #e5e5e5);
    }
    &.white {
        @include buttons-style(black, white, black, 1px solid #e5e5e5);
    }
    &.square {
        border-radius: 12px;
    }
    &.left {
        margin-left: 0;
        margin-right: auto;
    }
    &.right {
        margin-left: auto;
        margin-right: 0;
    }
}
.businessButtonText {
    font-weight: bold;
    font-style: normal;
    padding: 12px 24px;
    display: flex;
    text-align: center;
    align-items: center;

    &.normal {
        font-size: 16px;
        line-height: 24px;
        svg {
            margin-left: 12px;
        }
    }
    &.small {
        font-size: 14px;
        line-height: 22px;
        padding: 8px 16px;
        svg {
            margin-left: 8px;
        }
    }
}
