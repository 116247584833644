.videoHero {
    background-color: #000;
    max-width: 100%;
    padding: 80px 0px 80px 0px;
    position: relative;
    &Title {
        font-weight: bold;
        font-size: 56px;
        line-height: 64px;
        text-align: center;
        margin-bottom: 16px;
        color: #fff;
        &Colored {
            color: #b2fce4 !important;
        }
    }
    &Description {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        max-width: 100%;
        width: 872px;
        margin: 0 auto 80px auto;
    }
    &Container {
        border-radius: 24px;
        position: relative;
        max-width: 100%;
        margin: 0 auto;
    }
    &Content {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        margin: 80px auto 0 auto;
        padding: 32px;
        border-radius: 16px;
        max-width: 100%;
        width: 872px;
        p {
            margin: 0;
            color: #fff !important;
        }
        span {
            color: #fff !important;
        }
    }
    @media screen and(max-width:786px) {
        padding: 32px 0px;
        &Title {
            font-size: 40px;
            line-height: 52px;
        }
        &Description {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 32px;
        }
        &Container {
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
            max-width: 100vw;
            width: 100vw;
        }
        &Content {
            margin-top: 32px;
            padding: 24px;
            font-size: 20px;
            line-height: 28px;
        }
    }
}

.vimeoVideo {
    position: relative;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 24px;
    }
}
