.businessYoutubeEmbedContainer {
    max-width: 100%;
    width: 872px;
    height: auto;
    margin: 0 auto 44px auto;
}
.youtubeVideo {
    position: relative;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 24px;
    }
    @media screen and (max-width: 768px) {
        iframe {
            border-radius: 12px;
        }
    }
}
