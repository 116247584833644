.businessEmphasizedTextContainer {
    max-width: 100%;
    width: 872px;
    margin: 0px auto 60px auto;
}

.businessEmphasizedText {
    font-size: 40px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0 20px;
    margin: 0;

    &.light {
        color: #fff;
        * {
            color: #fff !important;
        }
    }

    * {
        font-size: inherit!important;
        line-height: inherit!important;
        font-weight: inherit!important;
    }

    a {
        color: #3375c9;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

@media screen and(max-width: 576px) {
    .businessEmphasizedTextContainer {
        margin: 0px auto 44px auto;
    }
    .businessEmphasizedText {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        padding: 0 15px;
    }
}
