.container {
    margin: 20px 0;
    position: relative;
    z-index: 0;
}

.itemContainer {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
    transition: all 0.3s ease-out;

    .question {
        cursor: pointer;
        display: flex;
        font-weight: 700;
        justify-content: space-between;
        margin-bottom: 8px;
        padding-right: 32px;
        position: relative;

        &::after {
            border-right: 1px solid #000;
            border-top: 1px solid #000;
            box-sizing: border-box;
            content: '';
            height: 13px;
            position: absolute;
            right: 5px;
            text-align: center;
            top: 10%;
            transform: rotate(135deg);
            transition: all 0.5s ease;
            width: 13px;
        }
    }
    .answer {
        font-weight: 400;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease;

        p {
          margin: 0;
        }
    }

    input[type='checkbox'] {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    &:not(:first-child) {
        border-top: 1px solid #e5e5e5;
        padding-top: 32px;
    }

    input[type='checkbox']:checked ~ .answer {
        max-height: 100vh;
        padding-bottom: 8px;
    }
    input[type='checkbox']:checked + .question::after {
        transform: rotate(-45deg);
    }
}
