@import '../../styles/_styles.scss';
.newsletterSignUpSection {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    position: relative;
    border-radius: 24px 24px 0px 0px;
    padding: 128px 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.newsletterSignUpSectionInfo {
    max-width: 648px;
    width: 100%;
}
.newsletterSignUpSectionTitle {
    font-size: 64px;
    font-weight: 700;
    line-height: 80px;
    margin-bottom: 16px;
}
.newsletterSignUpSectionDescription {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}
.newsletterSignUpSectionForm {
    width: fit-content !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid rgb(0 0 0 / 0.25);
    margin-top: 32px;
}
.newsletterSignUpSectionFormInput {
    background-color: transparent;
    height: 65px;
    outline: none;
    border: none;
    width: 314px;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
}

.newsletterSignUpSectionAfterSubmit {
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    display: flex;
    gap: 32px;

    flex-direction: row;
    align-items: center;
    padding-right: auto;

    animation-name: fade-in;
    animation-fill-mode: both;
    animation-duration: 1s;
}
// @media screen and (max-width: 1310px) {
//     .newsletterSignUpSection {
//         justify-content: center !important;
//     }
// }
@media screen and (max-width: 1050px) {
    .newsletterSignUpSection {
        flex-direction: column;
        gap: 50px;
    }
}
@media screen and (max-width: 768px) {
    .newsletterSignUpSection {
        padding: 34px 15px;
    }
    .newsletterSignUpSectionTitle {
        font-size: 40px;
        line-height: 52px;
    }
    .newsletterSignUpSectionForm {
        border: 0;
        & > :not(:last-of-type) {
            margin-bottom: 16px;
        }
    }
    .newsletterSignUpSectionFormInput {
        height: 37px;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        width: 100%;
        border-bottom: 1px solid rgb(0 0 0 / 0.25);
    }
    .newsletterSignUpSectionFormBtnSubmit {
        width: 100% !important;
    }
    .newsletterSignUpSectionDescription,
    .newsletterSignUpSectionFormInput {
        font-size: 20px;
        line-height: 28px;
    }
    .newsletterSignUpSectionAfterSubmit {
        font-size: 24px;
        line-height: 32px;
        gap: 24px;

        padding: 0;
        margin: 0 auto;
        width: fit-content;
        svg {
            height: 40px;
            width: 40px;
        }
    }
}
.newsletterSignUpSection {
    :global {
        .mktoForm {
            padding: 0 !important;
            padding-bottom: 16px !important;
            max-width: 100% !important;
            width: 535px !important;
            justify-content: space-between;
            flex-wrap: nowrap !important;
        }

        .mktoForm .mktoFormRow:not(:first-of-type) {
            display: none !important;
        }

        .mktoFormRow.mktoRequiredField {
            width: 100% !important;
        }
        .mktoFieldWrap.mktoRequiredField {
            width: 100% !important;
            max-width: 100%;
        }
        .mktoFieldDescriptor.mktoFormCol {
            margin-bottom: 0 !important;
        }
        .mktoForm input[type='email'] {
            background: transparent;
            border: 0 !important;
            outline: none;
            font-size: 24px;
            line-height: 32px;
            max-width: 100% !important;
            border-radius: 0px !important;
            font-weight: 400;
        }
        input::-webkit-input-placeholder {
            opacity: 0.5;
        }

        .mktoButtonRow {
            width: auto !important;
            min-width: 185px;
        }
        .mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
            background-color: black !important;
            color: white;
            border-radius: 50px;
            font-size: 16px !important;
            line-height: 24px !important;
            padding: 12px 24px !important;
            width: auto !important;
            transition: background-color 0.3s ease-in-out;
            border: 1px solid transparent;
            .button-submit {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                align-items: center;
                justify-content: center;

                .button-submit-title {
                    margin-right: 12px;
                    white-space: nowrap;
                    width: fit-content;
                }
            }
            svg {
                padding: 0;
            }
        }
        *:-webkit-autofill,
        *:-webkit-autofill:hover,
        *:-webkit-autofill:focus,
        *:-webkit-autofill:active {
            transition: all 5000s ease-in-out 0s;
            transition-property: background-color, color;
            -webkit-box-shadow: 0 0 0 1000px #b2fce4 inset;
            box-shadow: 0 0 0 1000px #b2fce4 inset;
            font-weight: 400 !important;
        }
        .mktoButtonWrap.mktoSimple .mktoButton:hover {
            border: 1px solid rgba(0, 0, 0, 0.1) !important;
            background-color: white !important;
            color: black;
            path {
                stroke: black !important;
            }
        }
        .mktoOffset {
            width: 0 !important;
        }
        @media screen and (max-width: 768px) {
            .mktoForm {
                flex-wrap: wrap !important;
            }
            .mktoButtonRow,
            .mktoButton {
                width: 100% !important;
            }

            .mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
                width: 100% !important;
            }
            .mktoForm input[type='email'] {
                font-size: 20px;
                line-height: 28px;
                border-bottom: 1px solid rgb(0 0 0 / 0.25) !important;
            }
        }
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.newsletterSignUpSectionInfoBackground {
    border-radius: 24px 24px 0px 0px;
    width: 100vw;
    min-width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
    height: 100%;
    background: #b2fce4;
}
