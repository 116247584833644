$business-trending-topics-prefix: businessTrendingTopics;

.#{$business-trending-topics-prefix}Wrapper {
    * {
        font-family: Italian Plate No2 Expanded;
    }

    max-width: 1440px;
    padding: 128px 0px 64px 0px;

    .#{$business-trending-topics-prefix} {
        &Title {
            font-size: 40px;
            font-weight: 700;
            line-height: 52px;
            margin-bottom: 8px;
        }
        &Description {
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            margin-bottom: 64px;
            max-width: 760px;
        }
        &Carousel {
            position: relative;
            .carousel {
                &ButtonNext,
                &ButtonPrev {
                    align-items: center;
                    background-color: black;
                    border-radius: 12px;
                    bottom: 50%;
                    cursor: pointer;
                    display: flex;
                    height: 56px;
                    justify-content: center;
                    opacity: 1;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: visibility 0s, opacity 0.5s ease-in-out;
                    visibility: visible;
                    width: 56px;
                    z-index: 2;
                    svg {
                        height: 20px;
                        width: 20px;
                    }
                    path {
                        stroke: white;
                    }
                    &:active {
                        background-color: rgb(0 0 0 / 0.8);
                    }
                    @media screen and(max-width:1338px) {
                        display: flex;
                    }
                }

                &ButtonNext {
                    right: -35px;
                }
                &ButtonPrev {
                    left: -35px;
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

            :global {
                .swiper-container {
                    height: 100%;
                    overflow: visible;

                    .swiper-wrapper {
                        transition-timing-function: linear;
                        user-select: none;
                        .swiper-slide {
                            transition-duration: 400ms !important;
                            width: auto;
                        }
                    }
                }
                .swiper-button-disabled {
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s, opacity 0.5s ease-in-out;
                }
            }
        }
    }
    @media screen and(max-width:1439px) {
        .#{$business-trending-topics-prefix} {
            &Carousel {
                :global {
                    .swiper-container {
                        width: 100vw;
                        min-width: 100vw;
                        margin-left: calc(50% - 50vw);
                        margin-right: calc(50% - 50vw);
                        padding-left: 60px;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 64px 15px 32px 15px;
        .#{$business-trending-topics-prefix} {
            &Title {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 8px;
            }
            &Description {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 32px;
                max-width: 345px;
            }
            &Carousel {
                :global {
                    .swiper-container {
                        padding-left: 15px;
                    }
                }
                .carousel {
                    &ButtonNext {
                        right: -7px !important;
                    }
                    &ButtonPrev {
                        left: -7px !important;
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1440px) {
        margin-left: auto;
        margin-right: auto;
    }
}
