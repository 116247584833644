.container {
    border-radius: 12px;
    display: flex;
    height: 138px;
    overflow: hidden;
    position: relative;
    transition: box-shadow 0.3s ease-in-out;
    width: 100%;

    &.bordered {
        border: 1px solid #e5e5e5;
    }

    &:hover {
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    }

    @media only screen and(max-width: 768px) {
        height: 112px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding: 16px;
    position: relative;
    width: 100%;

    .name {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 6px;
    }

    .description,
    .colorCode {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

    .description {
        margin-bottom: 8px;
    }

    .colorCode {
        text-transform: uppercase;
        &:not(:last-of-type) {
            margin-bottom: 4px;
        }

        &.copied {
            font-weight: 700;
            text-transform: none;
        }
    }

    .copyIcon {
        align-items: center;
        background-color: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 50%;
        cursor: pointer;
        display: inline-flex;
        height: 22px;
        justify-content: center;
        margin-right: 8px;
        position: relative;
        width: 22px;

        svg {
            max-height: 12px;
            max-width: 12px;
        }
    }

    @media only screen and(max-width: 768px) {
        .name {
            margin-bottom: 0;
        }
        .colorCode {
            &:not(:last-of-type) {
                margin-bottom: 1px;
            }
        }
    }
}
