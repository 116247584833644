.categoryFilter {
    padding: 32px 0px;
    span {
        margin: 0 8px;
    }
}
.filterButton {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;

    cursor: pointer;
    user-select: none;
    border-radius: 100px;

    width: fit-content;
    padding: 5px 16px;

    .filterIconDown {
        height: 20px;
        transform: rotate(0deg);
        transition: transform 0.15s ease-in-out;
    }

    &.close {
        .filterIconDown {
            transform: rotate(-180deg);
        }
    }
}
.categories {
    transition: all 0.3s;
    &.open {
        min-height: 44px;

        margin-top: 16px;
        .categoriesList {
            transform: scaleY(1);
            transition: transform 0.3s ease;
            transform-origin: top;
        }
    }
    &.close {
        margin-top: 0;
        max-height: 0;

        .categoriesList {
            transform: scaleY(0);
            transform-origin: top;
            transition: transform 0.3s ease;
        }
    }
}

.categoriesList {
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    color: #4d4d4d;
}
.categoryName {
    cursor: pointer;
    user-select: none;

    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: fit-content;
    padding: 5px 16px;
    border-radius: 25px;
    color: #4d4d4d;
    text-align: center;

    transition: background-color .3s, color .3s, border 0.3s ease-in;

    &.checked {
        background-color: #b2fce4;
        color: #000;
        border: 1px solid #b2fce4;
    }

    &:hover {
        color: #000 !important;
        border: 1px solid #000;
    }
}
.categoryName:not(:last-child) {
    margin-right: 4px;
}
.categoryName {
    margin-bottom: 8px;
}
@media screen and (max-width: 768px) {
    .categoryFilter {
        padding: 16px 15px;
    }
}
