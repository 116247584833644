.root {
    touch-action: manipulation;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    display: inline-flex;
    cursor: pointer;
    align-items: flex-start;
    line-height: 16px;
    &:after {
        display: inline-block;
        width: 0;
        overflow: hidden;
        content: '\a0';
    }
}

.checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    line-height: 1;
    white-space: nowrap;
    outline: none;
    cursor: pointer;
    & + span {
        padding-right: 8px;
        padding-left: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }
    &.checked {
        .inner {
            background-color: #3375C9;
            border-color: #3375C9;
            &:after {
                position: absolute;
                display: table;
                border: 2px solid #fff;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                opacity: 1;
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                content: ' ';
            }
        }
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid var(--ant-primary-color);
            border-radius: 2px;
            visibility: hidden;
            -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
            animation: antCheckboxEffect 0.36s ease-in-out;
            -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
            content: '';
        }
    }
}

.input {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
    touch-action: manipulation;
    position: absolute;
    inset: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #cccccc;
    border-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;
    &:after {
        position: absolute;
        top: 6px;
        left: 2px;
        display: table;
        width: 5.71428571px;
        height: 9.14285714px;
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(0) translate(-50%, -50%);
        opacity: 0;
        transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
        content: ' ';
    }
}
