$business-resouce-card-prefix: businessResourceCard;

.#{$business-resouce-card-prefix}Wrapper {
    margin: 32px auto 0 auto;
    max-width: 1440px;
    padding: 0;
    position: relative;
    width: 100%;

    * {
        font-family: Italian Plate No2 Expanded;
    }

    &:hover {
        .#{$business-resouce-card-prefix}BackgroundWrapper {
            .#{$business-resouce-card-prefix}BackgroundBox {
                .#{$business-resouce-card-prefix}Background {
                    -webkit-transform: scale(1.1);
                    -moz-transform: scale(1.1);
                    -o-transform: scale(1.1);
                    -ms-transform: scale(1.1);
                    transform: scale(1.1);
                }
            }
        }
    }

    .#{$business-resouce-card-prefix}BackgroundWrapper {
        overflow: hidden;
        padding-top: 41.5%;
        position: relative;
        .#{$business-resouce-card-prefix}BackgroundBox {
            background: transparent;
            border-radius: inherit;
            border-radius: 24px;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            transform: rotate(0);
            transition: transform 0.3s;
            width: 100%;
            .#{$business-resouce-card-prefix}Background {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;

                transform: rotate(0);
                transition: all 0.3s ease-in;
                width: 100%;
            }
        }
    }

    .#{$business-resouce-card-prefix}OverlayWrapper {
        background-color: #ffffff;
        border-radius: 16px;
        bottom: 24px;
        left: 24px;
        padding: 40px;
        position: absolute;
        width: 740px;

        transition: all 0.2s;

        .#{$business-resouce-card-prefix}Overlay {
            &Title {
                font-size: 64px;
                font-weight: bold;
                line-height: 72px;
                margin-bottom: 16px;
                color: black;
            }
            &Description {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 40px;
                color: black;
            }
            &CTA {
                background: #b2fce4;
                border-radius: 100px;
                color: #000000;
                font-size: 16px;
                font-weight: bold;
                height: 48px;
                line-height: 24px;
                padding: 12px 24px;
                text-align: center;
                text-decoration: none;
                transition: background-color 0.3s ease-in-out;
                vertical-align: center;
                width: fit-content;
                &:visited {
                    color: #000000;
                }

                svg {
                    margin-left: 12.5px;
                    vertical-align: middle;

                    path {
                        stroke: #000000;
                    }
                }

                &:hover {
                    background-color: #000000;
                    color: #ffffff;
                    svg {
                        path {
                            stroke: #ffffff;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 979px) {
        .#{$business-resouce-card-prefix}BackgroundWrapper {
            padding-top: 45%;
        }
        .#{$business-resouce-card-prefix}OverlayWrapper {
            .#{$business-resouce-card-prefix}Overlay {
                &Title {
                    font-size: 32px;
                    line-height: 40px;
                    margin-bottom: 8px;
                }
                &Description {
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 32px;
                }
            }
        }
    }

    @media only screen and (max-width: 908px) {
        .#{$business-resouce-card-prefix}OverlayWrapper {
            width: calc(100% - 168px);
        }
    }

    @media only screen and (max-width: 768px) {
        .#{$business-resouce-card-prefix}OverlayWrapper {
            border-radius: 8px;
            left: auto;
            margin-bottom: -105px;
            margin-left: 16px;
            margin-right: 16px;
            padding: 24px 24px 0px;
            position: relative;

            transform: translateY(-60%);
            width: auto;
            .#{$business-resouce-card-prefix}Overlay {
                &Title {
                    font-size: 32px;
                    line-height: 40px;
                    margin-bottom: 8px;
                }
                &Description {
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 32px;
                }

                &CTA {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and(max-width:576px) {
        .#{$business-resouce-card-prefix}BackgroundWrapper {
            padding-top: 80%;
        }
    }

    @media only screen and(max-width:375px) {
        .#{$business-resouce-card-prefix}BackgroundWrapper {
            padding-top: 121.8%;
        }

        .#{$business-resouce-card-prefix}OverlayWrapper {
            margin-bottom: -131px;
        }
    }
}
