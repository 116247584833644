.businessArticleImageContainer {
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto 44px auto;
}
.businessArticleImageWrapper {
    max-width: 100%;
    width: 872px;
    margin: 0 auto;
}
.businessArticleImageBox {
    border-radius: 24px;
    width: 100%;
    padding-top: 100%;
    background: transparent;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s;
    transform: rotate(0);
    &.squared {
        border-radius: 0 !important;
    }
}
.businessArticleImage {
    cursor: pointer;
    transition: all 0.3s ease-in;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
@media screen and(max-width: 768px) {
    .businessArticleImageBox {
        border-radius: 12px;
    }
}
