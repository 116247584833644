.businessTextCaptionContainer {
    max-width: 100%;
    width: 648px;
    margin: 0px auto 44px auto;
    a {
        color: #3375c9;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.businessTextCaption {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #666666;
    p {
        margin: 0;
    }
}
@media screen and (max-width: 768px) {
    .businessTextCaptionContainer {
        padding: 0 15px;
    }
    .businessTextCaption {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }
}
