.businessVimeoEmbedContainer {
    height: auto;
    margin: 0 auto 44px auto;
    max-width: 100%;
    width: 872px;
}
.vimeoVideo {
    position: relative;
    iframe {
        border-radius: 24px;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .video-controls {
        background-color: none;
        border-radius: 24px;
        height: 0;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transition: opacity 700ms, background-color 700ms, visibility 700ms;
        transition: opacity 700ms, background-color 700ms, visibility 700ms;
        width: 0;
        z-index: 3;

        &.full-iframe {
            width: 100%;
            height: 100%;
        }

        &.pause {
            background-color: #00000025;
            height: 100%;
            opacity: 1;
            width: 100%;
        }

        &__btnPlayPause {
            align-items: center;
            backdrop-filter: blur(8px);
            background-color: transparent;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            height: 120px;
            justify-content: center;
            left: 50%;
            margin: 0;
            position: absolute;
            top: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            transition: all 0.5s ease-in-out;
            width: 120px;

            &::after {
                border-bottom: 25px solid transparent;
                border-left: 45px solid #ffffff;
                border-top: 25px solid transparent;
                content: '';
                height: 0;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-31%, -50%);
                transition: transform 0.3s ease-in-out;
                width: 0;
            }
            &::before {
                content: '';
                height: 30%;
                left: calc(50% - 10px);
                position: absolute;
                top: 50%;
            }

            &.play {
                &::after {
                    background: #ffffff;
                    border: none;
                    content: '';
                    height: 30%;
                    left: calc(50% + 10px);
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 10px;
                }
                &::before {
                    background: #ffffff;
                    border: none;
                    content: '';
                    height: 30%;
                    left: calc(50% - 10px);
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 10px;
                }
            }

            @media only screen and (max-width: 768px) {
                height: 80px;
                width: 80px;
                &::after {
                    border-bottom-width: 15px;
                    border-left-width: 26px;
                    border-top-width: 15px;
                }

                &.play {
                    &::after,
                    &::before {
                        width: 5px;
                    }
                    &::after {
                        left: calc(50% + 5px);
                    }
                    &::before {
                        left: calc(50% - 5px);
                    }
                }
            }
        }

        &__btnMuted {
            cursor: pointer;
            position: absolute;
            right: 16px;
            top: 16px;
            transition: all 0.5s ease-in-out;
            z-index: 2;
        }
        &__btnTopRight {
            cursor: pointer;
            left: 16px;
            position: absolute;
            top: 23px;
            transition: all 0.5s ease-in-out;
            z-index: 4;
            @media screen and( min-width:768px) {
                .icon-back {
                    display: none;
                }
            }
            @media screen and( max-width:768px) {
                .icon-close {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        iframe,
        .video-controls {
            border-radius: 12px;
        }
    }
}
