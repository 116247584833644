.container {
    margin: 16px 0;
    padding-top: 364px;
    position: relative;
    width: 100%;
    z-index: 0;

    &.withDownloadButton {
      padding-top: 448px;
    }

    .content {
        left: 0;
        position: absolute;
        top: 0;
        width: 100vw;
    }

    .bannerPreview {
        height: 364px;
        width: 183px;
    }

    .download {
        margin-top: 32px;
    }

    :global {
        .swiper-container {
            .swiper-wrapper {
                .swiper-slide {
                    width: auto;
                }
            }
        }
    }

    @media only screen and(max-width: 992px) {
        margin-left: calc(50% - 50vw);
        padding-top: 0;
        width: 100vw;
        &.withDownloadButton {
          padding-top: 0;
        }
        .content {
            position: relative;

            :global {
                .swiper-container {
                    padding-left: 64px;
                    padding-right: 64px;
                    width: 100vw;
                }
            }
        }

        .download {
            margin-left: 64px;
        }
    }

    @media screen and (max-width: 768px) {
        .content {
            :global {
                .swiper-container {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }

        .download {
            margin-left: 15px;
            margin-right: 15px;
            button {
              width: 100% !important;
            }
        }
    }
}
