.root {
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
        margin-top: 16px;
        margin-bottom: 16px;
    }
}
.container {
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    max-width: 100%;
    @media screen and (max-width: 768px) {
        margin-top: 32px;
        flex-direction: column;
        gap: 32px;
    }
}

.item {
    flex-basis: 50%;
    flex-shrink: 1;
}

.image {
    width: 100%;
    max-width: 100%;
}

.variant {
    box-sizing: content-box;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > * {
        flex-shrink: 1;
    }
}

.leftBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.variantImage {
    cursor: pointer;
}
