.container {
    border-radius: 16px;
    margin-bottom: 40px;
    margin-top: 40px;
    position: relative;
    overflow: hidden;
}

.title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 32px;
}

.items {
    .item {
        border-radius: 16px;
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 300px;
        overflow: hidden;
        padding: 32px;
        position: relative;
        width: 100%;
        transform: translateZ(0);

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        p {
            margin: 0;
        }

        &:hover {
            .backgroundWrapper {
                .backgroundBox {
                    .background {
                        -webkit-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        -ms-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    .itemMask {
        background: linear-gradient(255.82deg, rgba(0, 0, 0, 0) 39.77%, rgba(0, 0, 0, 0.2) 52.12%);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .backgroundWrapper {
        height: 100%;
        left: 0;
        overflow: hidden;
        padding-top: 41.5%;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;

        .backgroundBox {
            background: transparent;
            border-radius: inherit;
            border-radius: 16px;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            transform: rotate(0);
            transition: transform 0.3s;
            width: 100%;
            .background {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;

                transform: rotate(0);
                transition: all 0.3s ease-in;
                width: 100%;
            }
        }
    }

    .itemTitle {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        margin-bottom: 6px;
        margin-top: auto;
    }

    .itemDescription {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}

@media only screen and (max-width: 992px) {
    .container {
        margin-bottom: 16px;
        margin-top: 16px;
    }
    .title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
    }

    .items {
        .item {
            padding: 16px;
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
        .itemMask {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        }
        .itemTitle {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 6px;
        }

        .itemDescription {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }
    }
}
