@import '../../styles/_styles.scss';
.videoWrap {
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    transition: all 0.3s ease-out;
    cursor: pointer;
    &ButtonPlay {
        z-index: 3;
        border-radius: 50%;
        background: rgb(0 0 0 / 0.6);
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -100%);
        height: 120px;
        width: 120px;

        svg {
            transform: translate(calc(-50% + 5px), -50%);
            top: 50%;
            left: 50%;
            position: absolute;
            width: 53px;
            height: 53px;
        }
        @media screen and (max-width: 786px) {
            height: 64px;
            width: 64px;
            top: 38%;
            svg {
                transform: translate(calc(-50% + 3px), -50%);
                width: 28px;
                height: 28px;
            }
        }
    }
}
.videoWrap:hover {
    .videoContainerWatchNow {
        background-color: white;
        color: black;
        svg > path {
            stroke: black !important;
        }
    }
    :global {
        .image-zoom {
            .image-hover-zoom {
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -o-transform: scale(1.1);
                -ms-transform: scale(1.1); /* IE 9 */
                transform: scale(1.1);
            }
        }
    }
}
.videoVideo {
    position: relative;
    max-height: 646px;
}
.videoOverlay {
    border-radius: 12px;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 27.14%, rgba(0, 0, 0, 0.5) 53.08%);
}
.videoContainer {
    color: white;
    position: absolute;
    bottom: 40px;
    right: 40px;
    left: 40px;
    z-index: 2;
    &ImageBrand {
        max-width: 168px;
        max-height: 32px;
    }
    &Title {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        text-align: left;
        margin-top: 16px;
        margin-bottom: 24px;
    }
    &Description {
        color: white;
        font-size: 20px;
        line-height: 28px;
        display: -webkit-box;
        margin: 0 auto;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0;
        margin-bottom: 24px;
    }
    &WatchNow {
        color: white;
        white-space: nowrap;
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        padding: 11px 24px;
        background-color: transparent;
        border-radius: 50px;
        width: fit-content;
        display: flex;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        align-items: center;
        svg {
            margin-left: 12px;
        }
        @include buttons-style(white, black, white, 1px solid #fff);

        bottom: 0;
    }
    @media screen and(max-width:986px) {
        left: 16px;
        right: 16px;
        bottom: 16px;
        &Title {
            font-size: 32px;
            line-height: 40px;
        }
        &WatchNow {
            width: 100%;
        }
    }
    @media screen and (max-width: 576px) {
        &Description {
            display: none;
        }
        &Title {
            margin-bottom: 8px;
        }
    }
}
.videoLink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
}
