.discountCarousel {
    position: relative;
    z-index: 3;
    :global {
        .swiper-container {
            height: 100%;
            overflow: visible;

            .swiper-wrapper {
                transition-timing-function: linear;
                user-select: none;
                .swiper-slide {
                    transition-duration: 400ms !important;
                    width: auto;
                    position: relative;
                }
            }
        }
        .swiper-button-disabled {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s;
        }
    }
    &ButtonNext,
    &ButtonPrev {
        align-items: center;
        background-color: #b2fce4;
        border-radius: 12px;
        bottom: 50%;
        cursor: pointer;
        display: none;
        justify-content: center;
        opacity: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        visibility: visible;
        height: 56px;
        width: 56px;
        z-index: 2;
        svg {
            height: 20px;
            width: 20px;
        }
        path {
            stroke: #000;
            stroke-width: 1.5px;
        }
        &:active {
            background-color: rgb(178 252 228 / 0.8);
        }
        @media screen and(max-width:1200px) {
            display: flex;
        }
        @media screen and (max-width: 786px) {
            height: 56px;
            width: 56px;
            svg {
                height: 16px;
                width: 16px;
            }
        }
    }

    &ButtonNext {
        right: -35px;
    }
    &ButtonPrev {
        left: -35px;
        svg {
            transform: rotate(180deg);
        }
    }
    &Slider {
        width: 1096px;
        max-width: 100%;
        margin: 0 auto;
        :global {
            .swiper-wrapper {
                height: auto;
                display: grid;
                width: 100%;
                gap: 24px;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: 256px;
            }
        }
    }
    &Slide {
        height: 256px;
        width: 100% !important;
        border-radius: 8px;
        &.columnSpan {
            grid-column: span 2;
        }
        &.columnSpanQuote {
            grid-column: span 3;
        }
    }
    @media screen and(max-width:1200px) {
        &Slider {
            :global {
                .swiper-wrapper {
                    height: auto;
                    display: grid;
                    width: 100%;
                    display: flex;
                    gap: unset;
                }
            }
        }
        &Slide {
            height: 256px;
            width: 256px !important;
        }
    }
    @media screen and(max-width: 786px) {
        &ButtonNext {
            right: -7px !important;
        }
        &ButtonPrev {
            left: -7px !important;
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
