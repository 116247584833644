.root {
    font-weight: 700;
    font-size: 80px;
    line-height: 80px;
    margin-top: 16px;
    margin-bottom: 16px;
    @media screen and (max-width: 768px) {
        font-size: 56px;
        line-height: 64px;
    }
}
