.root {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
}

.imageContainer {
    position: relative;
    padding-top: 86px;
    width: 100%;
}

.image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}
