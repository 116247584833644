@import '../../styles/_styles.scss';

.container {
    &:hover {
        .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }

    .download {
        font-family: 'Italian Plate No2 Expanded';
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
            box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
        }

        &:not(.icon) {
            @include buttons-style(black, white, black);

            align-items: center;
            background: #b2fce4;
            border-radius: 12px;
            display: flex;
            font-size: 14px;
            font-weight: 700;
            justify-content: center;
            line-height: 22px;
            padding: 13px 24px;
            width: fit-content;

            svg {
                height: 16px;
                margin-left: 8px;
                width: 16px;
            }
        }

        &:disabled {
            @extend .buttonDisable;
            cursor: wait;

            &:hover {
                @extend .buttonDisable;
            }
        }

        &.icon {
            align-items: center;
            background: #ffffff;
            border: 1px solid #e5e5e5;
            border-radius: 100px;
            cursor: pointer;
            display: flex;
            height: 24px;
            justify-content: center;
            width: 24px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .tooltip {
        background-color: black;
        border-radius: 12px;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        opacity: 0;
        padding: 14px 16px;
        transition-duration: 0.5s;
        transition-property: opacity, visibility;
        transition-timing-function: ease;
        visibility: hidden;

        .tooltipArrow {
            display: none;
            height: 12px;
            width: 12px;
            &.visible {
                display: block;
            }

            &::before {
                background: #000;
                border-radius: 2px;
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                transform: translateX(0px) rotate(45deg);
                transform-origin: center;
                width: 100%;
                z-index: -1;
            }
        }

        &[data-popper-placement='top'] {
            margin-bottom: 18px;

            .tooltipArrow {
                bottom: 0;
                &::before {
                    margin-top: 5px;
                }
            }
        }

        &[data-popper-placement='bottom'] {
            margin-top: 18px;

            .tooltipArrow {
                top: 0;
                &::before {
                    margin-top: -5px;
                }
            }
        }

        .button {
            background-color: unset;
            border: 1px solid #4d4d4d;
            border-radius: 8px;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            padding: 3px 8px;
            transition: all 0.3s ease;

            &:hover {
                background-color: #fff;
                color: #000;
            }

            &:not(:first-child) {
                margin-left: 4px;
            }

            &:disabled {
                cursor: wait;
                background-color: #666666;
                color: #000;
            }
        }
    }
}
.mobileFullWidth {
    @media screen and (max-width: 992px) {
        width: 100% !important;
    }
}

.buttonDisable {
    background-color: #cccccc !important;
    color: #666666;

    &:hover {
        box-shadow: none;
    }

    svg {
        path {
            stroke: #666666 !important;
        }
    }
}
