.root {
    margin-bottom: 40px;
    margin-top: 40px;
    @media screen and (max-width: 768px) {
        margin-bottom: 16px;
        margin-top: 16px;
    }
}

.headline {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 16px;
}

.text {
    font-weight: 700;
    font-size: 80px;
    line-height: 80px;
    color: #000000;
    @media screen and (max-width: 768px) {
        font-size: 40px;
        line-height: 52px;
    }
}
