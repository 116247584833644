.videoCarouselQuoteItem {
    text-align: center;
    color: #fff;
    max-width: 100%;
    &Content {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
    }
    &AuthorName {
        margin-top: 4px;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0;
    }
    @media screen and (max-width: 786px) {
        &Content {
            font-size: 20px;
            line-height: 28px;
        }
    }
}
