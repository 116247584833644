.container {
    background: #eff4fb;
    border-radius: 8px;
    padding: 8px 8px 6px 16px;

    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;

    &:hover {
        .copyIcon {
            opacity: 1;
        }
    }

    p {
        margin: 0;
    }

    @media only screen and(max-width: 992px) {
        font-size: 20px;
        line-height: 28px;
    }
}

.copyIcon {
    flex-shrink: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    height: 22px;
    justify-content: center;
    margin-right: 8px;
    position: relative;
    width: 22px;

    svg {
        max-height: 12px;
        max-width: 12px;
    }
}

.tooltip {
    background-color: black;
    border-radius: 10px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    opacity: 0;
    padding: 8px 8px;
    transition-duration: 0.5s;
    transition-property: opacity, visibility;
    transition-timing-function: ease;
    visibility: hidden;
    position: relative;

    &.visible {
        opacity: 1;
        visibility: visible;
    }

    .tooltipArrow {
        height: 10px;
        width: 10px;

        &::before {
            background: #000;
            border-radius: 2px;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transform: translateX(0px) rotate(45deg);
            transform-origin: center;
            width: 100%;
            z-index: -1;
        }
    }

    &[data-popper-placement='top'] {
        margin-bottom: 10px;

        .tooltipArrow {
            bottom: 0;
            &::before {
                margin-top: 5px;
            }
        }
    }

    &[data-popper-placement='bottom'] {
        margin-top: 10px;

        .tooltipArrow {
            top: 0;
            &::before {
                margin-top: -5px;
            }
        }
    }
}
