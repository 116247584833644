.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 24px;
}

.image {
    height: 80px;
    margin-top: auto;
    position: relative;
    width: 100%;

    img {
        height: 100%;
        object-fit: contain;
        width: 100%;
    }
}

@media only screen and(max-width: 768px) {
    .image {
        img {
            object-position: left;
        }
    }
}
