.box {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 32px;

    @media only screen and(max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and(max-width: 768px) {
        grid-template-columns: 1fr;

        .downloadButton {
            background-color: red;
            > * {
                width: 100%;
            }
        }
    }
}

@media only screen and(max-width: 768px) {
    .downloadButton {
        button {
            width: 100% !important;
        }
    }
}
