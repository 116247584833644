.headliningArticlesBlock {
    width: 1440px;
    margin: 0 auto;
    max-width: 100%;
    padding: 64px 0px 0 0px;
    position: relative;
    :global {
        .swiper-slide {
            width: fit-content !important;
        }
    }
}

.swiperButtonNext,
.swiperButtonPrev {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    bottom: 50%;
    z-index: 2;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 56px;
    background-color: black;
    border-radius: 12px;
    cursor: pointer;
    svg {
        height: 20px;
        width: 20px;
    }
    path {
        stroke: white;
    }
    &:active {
        background-color: rgb(0 0 0 / 0.8);
    }
    opacity: 1;
    visibility: visible;
    transition: visibility 0s;
}
.swiperButtonNext {
    right: 8px;
}
.swiperButtonPrev {
    left: 8px;
    svg {
        transform: rotate(180deg);
    }
}
.headliningArticlesBlock {
    :global {
        .swiper {
            overflow: auto;
        }
        .swiper-container {
            overflow: unset;
        }
        .swiper-slide {
            max-width: 488px;
            padding: 32px 0;
        }
        .swiper.swiper-initialized {
            overflow: inherit;
        }
        .swiper-button-disabled {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s;
        }

        @media screen and (max-width: 1439px) {
            .swiper.swiper-initialized {
                overflow: hidden;
            }
            .swiper-container {
                overflow: visible;
                width: calc(100vw - (50vw - 50%));
                min-width: calc(100vw - ((50vw - 50%)));
                margin-left: calc(50% - 50vw);
                margin-right: 0;
                padding-left: calc(50vw - 50%);
            }
        }
        @media screen and(max-width:768px) {
        }
        @media screen and(max-width:768px) {
            .swiper-container {
                overflow: visible;
                padding-left: 0;
                height: 439px !important;
                width: 100%;
                min-width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &SlideLarge {
        width: 872px;
        max-width: 872px !important;
    }
}
@media screen and(max-width:1440px) and (min-width: 769px) {
    .swiperButtonNext {
        right: 48px;
    }
    .swiperButtonPrev {
        left: 48px;
    }
}

@media screen and(max-width:1200px) {
    .headliningArticlesBlock {
        padding: 28px 0 0 0;
    }
}
@media screen and(max-width:768px) {
    .headliningArticlesBlock {
        padding: 32px 0 0 0;

        :global {
            .swiper-slide {
                width: 300px !important;
                height: 375px;
            }
        }
    }
}
