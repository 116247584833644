.root {
    margin-bottom: 16px;
    margin-top: 16px;
    // reset p tag
    p {
        margin: 0;
    }
    &.row {
        gap: 24px;
        display: flex;
        flex-direction: row;
        .caption {
            flex-basis: 200px;
            flex-shrink: 0;
            margin-bottom: 0;
        }
        .content {
            flex-basis: 100%;
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
            .caption {
                flex-basis: unset;
            }
            .content {
                flex-basis: unset;
            }
        }
    }
}

.caption {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 16px;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 32px;
    }
}

.content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    strong {
        font-size: 20px;
        line-height: 28px;
    }
    code {
        font-family: 'Roboto Mono', monospace;
        color: #666666;
    }
}
