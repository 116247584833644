.container {
    position: relative;
}

.headline {
    gap: 16px;
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 32px;
    font-weight: 400;
    justify-content: flex-start;
    line-height: 40px;
    margin-bottom: 23px;
}

.preview {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);

    .left {
        font-size: 144px;
        line-height: 128px;
    }

    .right {
        font-size: 24px;
        line-height: 32px;
    }
}

.font-italic {
    font-style: italic;
}

.font-light {
    font-weight: 200;
}

.font-regular {
    font-weight: 400;
}

.font-demibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

@media only screen and(max-width: 1200px) {
    .headline {
        font-size: 24px;
        line-height: 32px;
    }

    .preview {
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(2, 1fr);

        .left {
            font-size: 100px;
            line-height: 100px;
        }

        .right {
            font-size: 20px;
            line-height: 28px;
        }
    }
}

@media only screen and(max-width: 768px) {
    .preview {
        grid-template-columns: 1fr;
    }
}
