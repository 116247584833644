.container {
    position: relative;
    width: 100%;
    z-index: 0;
    grid-column: span 3;
    margin-top: -64px;
    margin-bottom: 64px;

    @media only screen and(max-width: 992px) {
        margin-top: 0;
    }
}

.background {
    left: 0;
    margin-left: calc(50% - 50vw);
    min-height: 100%;
    position: absolute;
    top: 0;
    width: 100vw;
    background: #b2fce4;
    border-radius: 0px 0px 24px 24px;
    z-index: -1;
}

.title {
    font-weight: 700;
    font-size: 100px;
    line-height: 100px;
    padding: 120px 0;

    @media only screen and(max-width: 992px) {
        padding: 80px 0 64px 0;
        font-size: 56px;
        line-height: 64px;
    }
}

.filter {
    &Container {
    }
    &Title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
    }
}
