@import '../../styles/_styles.scss';
.businessArticleHeroContainer {
    margin: 64px auto 80px auto;
    max-width: 100%;
    padding: 0 60px 0 60px;

    position: relative;
    width: 1440px;

    z-index: 0;
    &.marginLower {
        margin: 0px auto 80px auto;
    }

    &.isFeatured {
        padding: 88px 0px 0px 0px;

        .breadcrumb {
            left: 0;
            position: absolute;
            top: 0;
            z-index: 200;
        }
        .businessArticleHeroInfoWrapper {
            > div {
                bottom: 24px;
                left: 24px;
                @media only screen and (max-width: 768px) {
                    left: 0;
                }
            }
        }
    }
}
.businessArticleHeroWrapper {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 100%;

    @include paddingTopByPercent(1440, 736);
    @media screen and(max-width:768px) {
        @include paddingTopByPercent(768, 420);
    }
}
.businessArticleHeroBox {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
}

@media only screen and(max-width:768px) {
    .businessArticleHeroContainer {
        margin-bottom: 0px;
        padding: 0;
        &.isFeatured {
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
            max-width: 100vw;
            padding: 0;
            width: 100vw;
        }
        &.marginLower {
            margin-bottom: 0px;
            :global {
                .article-hero-info {
                    margin-bottom: 0;
                }
            }
        }
    }
    .breadcrumb {
        padding-left: 15px;
        padding-right: 15px;
    }

    .businessArticleHeroWrapper {
        margin-top: -58px;
        z-index: -1;
        min-height: 420px;
        &::before {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 24.75%, rgba(0, 0, 0, 0) 39.97%);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: -64px;
            width: 100%;
            z-index: 1;
        }
    }
}

@media screen and(min-width:768px) {
    .businessArticleHeroBox {
        border-radius: 24px;
    }
}

@media screen and(min-width:1440px) {
    .businessArticleHeroBox {
        border-radius: 24px;
        bottom: 0;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
}
