.discountCarouselItem {
    &Image,
    &Quote,
    &Discount,
    &ImageBrand,
    &CTABlock {
        border-radius: inherit;
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: relative;
        cursor: default;
    }
    &Image:hover {
        :global {
            .image-hover-zoom {
                cursor: default;
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -o-transform: scale(1.1);
                -ms-transform: scale(1.1); /* IE 9 */
                transform: scale(1.1);
            }
        }
    }
    &Quote {
        padding: 60px 40px;
        text-align: center;
        color: #fff;
        background: #333333;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        &Content {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            margin: 0;
            max-width: 456px;
        }
        &AuthorName {
            margin-top: 16px;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 0;
        }
        @media screen and(max-width:1200px) {
            padding: 32px;
            &Content {
                font-size: 20px;
                line-height: 24px;
            }
            &AuthorName {
                margin-top: 8px;
                font-size: 12px;
                line-height: 20px;
            }
        }
    }
    &Quote.singleBlock {
        padding: 32px !important;
        .discountCarouselItemQuoteContent {
            font-size: 20px !important;
            line-height: 24px !important;
        }
        .discountCarouselItemQuoteAuthorName {
            margin-top: 8px !important;
            font-size: 12px !important;
            line-height: 20px !important;
        }
    }
    &Discount {
        color: #000;
        text-align: center;
        background: #b2fce4;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        &Percent {
            margin: 0;
            font-weight: bold;
            font-size: 56px;
            line-height: 64px;
            margin-top: 0;
        }
        &Text {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            margin-top: 8px;
            margin-bottom: 0;
        }
        :global {
            .image-discount.image-zoom {
                height: 100%;
                width: 100%;
                .image-hover-zoom {
                    cursor: default !important;
                    -webkit-transform: scale(1);
                    -moz-transform: scale(1);
                    -o-transform: scale(1);
                    -ms-transform: scale(1);
                    transform: scale(1);
                }
            }
        }
    }
    &ImageBrand {
        background-color: #fff;
        img {
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            mix-blend-mode: multiply;
            object-fit: cover;
        }
    }
    &CTABlock {
        border: 4px solid #ffffff;
        position: relative;
        border-radius: 8px;
        background-color: #000;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &Content {
            color: #fff;
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            margin-top: 0;
            margin-bottom: 16px;
        }
        &Url {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
        &:hover > &Btn {
            background-color: #fff;
            svg {
                stroke: #fff;
            }
        }
        &Btn {
            background-color: #b2fce4;
            transition: background-color 0.3s ease-in-out;
            color: #000;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 16px;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
            border-radius: 100px;
            width: fit-content;
            margin: 0 auto;
            svg {
                margin-left: 8px;
            }
        }
    }
}
