.root {
    position: relative;
}

.text {
    margin-top: 16px;
    margin-bottom: 20px;
    padding: 24px;
    background: #eff4fb;
    border-radius: 12px;
    p {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    strong {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #000000;
        margin-bottom: 8px;
        //display: block;
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 16px;
    }
}

.copyButton {
    position: absolute;
    top: 8px;
    right: 8px;
}
