.root {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 24px;
    @media screen and (max-width: 768px) {
        gap: 32px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

.resourceContainer {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
}

.pagePreviewImage {
    max-width: 424px;
    width: 100%;
}
