$business-resouce-related-prefix: businessResourceRelated;

.#{$business-resouce-related-prefix} {
    &Container {
        max-width: 100%;

        margin-left: auto;
        margin-right: auto;

        .#{$business-resouce-related-prefix} {
            &Title {
                font-size: 40px;
                font-weight: 700;
                line-height: 52px;
                margin-bottom: 8px;
            }
            &Description {
                font-size: 20px;
                font-weight: 400;
                line-height: 28px;
                margin-bottom: 64px;
                max-width: 760px;
            }
            &Carousel {
                position: relative;
                .carousel {
                    &ButtonNext,
                    &ButtonPrev {
                        align-items: center;
                        background-color: black;
                        border-radius: 12px;
                        bottom: 50%;
                        cursor: pointer;
                        display: none;
                        height: 56px;
                        justify-content: center;
                        opacity: 1;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        transition: visibility 0s, opacity 0.5s ease-in-out;
                        visibility: visible;
                        width: 56px;
                        z-index: 2;
                        svg {
                            height: 20px;
                            width: 20px;
                        }
                        path {
                            stroke: white;
                        }
                        &:active {
                            background-color: rgb(0 0 0 / 0.8);
                        }
                        @media screen and(max-width:1338px) {
                            display: flex;
                        }
                    }

                    &ButtonNext {
                        right: 8px;
                    }
                    &ButtonPrev {
                        left: 8px;
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }

                :global {
                    .swiper-container {
                        height: 100%;
                        overflow: visible;
                        
                        .swiper-wrapper {
                            transition-timing-function: linear;
                            user-select: none;
                            .swiper-slide {
                                transition-duration: 400ms !important;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
