.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1d1d1d;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.root {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px 24px;
    @media screen and (max-width: 992px) {
        grid-template-columns: 1fr;
    }
}

.reset {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
    color: #808080;
}

.keyMessages {
    grid-row: 1/3;
    grid-column: 2;
    @media screen and (max-width: 992px) {
        grid-row: 3;
        grid-column: 1;
    }
}
